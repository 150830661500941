import { Visibility as VisibilityOnIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { Alert, Box, Button, IconButton, InputAdornment, LinearProgress, Paper, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import LanguageSelector from 'components/selectors/LanguageSelector';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useLoginMutation, apiSlice } from 'features/api/apiSlice';
import { selectCurrentUser, setCredentials } from 'features/auth/authSlice';
import useProvideClient from 'hooks/client/useProvideClient';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

export default function LoginPage() {
  const clientProvider = useProvideClient();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [login] = useLoginMutation();
  const navigate = useNavigate();
  const authUser = useAppSelector(selectCurrentUser);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isLoggingIn, setIsLogging] = useState(false);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);

  if (authUser) {
    return <Navigate to="/patients" />; // redirect to profile page if already logged in
  }

  const handleEmailChange: (event: any) => void = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange: (event: any) => void = (event) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword: () => void = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword: (event: any) => void = (event) => {
    event.preventDefault();
  };

  const handleSubmit: (event: any) => void = async e => {
    e.preventDefault()

    setIsLogging(true)
    await login({email, password})
    .unwrap()
    .then((result) => {
        setIsLogging(false)
        setShowError(false)
        dispatch(apiSlice.util.resetApiState())
        dispatch(setCredentials(result.data))
        navigate("/users")
    })
    .catch((error) => {
      setIsLogging(false);
      setError(t('wrong_email_password', 'Wrong email or password.')!!);
      setShowError(true);
    })
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage: `url("data:image/svg+xml;base64,${btoa(clientProvider.client.backgroundLoginLeft)}")`,
          backgroundRepeat: 'repeat',
          backgroundSize: 'inherit',
          backgroundPosition: 'center'
        }}
      />
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
        <Stack sx={{ position: "fixed", top: '2%', right: '1%' }}>
          <LanguageSelector />
        </Stack>
        <Stack
          width='100%'
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box component="img" src={clientProvider.client.logo} maxWidth='30%' sx={{ my: 2 }} />

          {isLoggingIn ?
            <LinearProgress sx={{ width: "60%" }} />
            :
            <>
              <Typography component="h1" variant="h6" color="#b8bacc">
                {t("enter_email_and_password", "Enter email and password for access.")}
              </Typography>
              <Stack component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, height: '100%', width: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TextField
                  variant='standard'
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t("email", "Email")}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={handleEmailChange}
                />

                <TextField
                  variant='standard'
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t("password", "Password")}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={handlePasswordChange}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                        </IconButton>
                      </InputAdornment>
                  }}
                />

                {showError &&
                  <Alert sx={{ width: '100%', my: 2 }} severity="error">
                    {error}
                  </Alert>
                }

                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={!email || !password}
                >
                  {t("login", "Login")}
                </Button>

                <Link variant="h6" component={RouterLink} to="/forgot-password" color="#b8bacc">
                  {t("forgot_password", "Forgot password?")}
                </Link>
              </Stack>
            </>
          }
        </Stack>
      </Grid>
    </Grid>
  );
}