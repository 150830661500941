import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';

// project import
import NavGroup from './NavGroup';

import { useSelector } from 'store';
import useConfig from 'hooks/config/useConfig';
import { HORIZONTAL_MAX_ITEM } from 'config';

// types
import { NavItemType } from 'types/menu';
import { MenuOrientation } from 'types/config';
import { useTranslation } from 'react-i18next';
import { PieChartOutlined, UserOutlined } from '@ant-design/icons';
import { useAppSelector } from 'app/hooks';
import { selectCurrentUser } from 'features/auth/authSlice';
import { UserProviderId, UserRole } from 'models/IUser';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const { t } = useTranslation();
  
  const authUser = useAppSelector(selectCurrentUser);

  const administration: NavItemType = {
    id: 'group-administration',
    icon: PieChartOutlined,
    type: 'group',
    children: [
      {
        id: 'collapse-administration',
        title: t('administration', 'Administration'),
        type: 'collapse',
        icon: PieChartOutlined,
        children: [
          {
            id: 'item-devices',
            title: t('devices', 'Devices'),
            type: 'item',
            url: '/admin/devices'
          },
          {
            id: 'item-users',
            title: t('users', 'Users'),
            type: 'item',
            url: '/admin/users'
          },
          {
            id: 'item-organizations',
            title: t('organizations', 'Organizations'),
            type: 'item',
            url: '/admin/organizations'
          },
          ...(authUser!!.role!! === UserRole.Admin ? [{
            id: 'item-literals',
            title: t('literals', 'Literals'),
            type: 'item',
            url: '/admin/literals'
          }] : [])
        ]
      }
    ]
  };

  const patients: NavItemType = {
    id: 'group-patients',
    icon: UserOutlined,
    type: 'group',
    children: [
      {
        id: 'item-patients',
        title: authUser?.provider !== UserProviderId.SMILE ? t('patients', 'Patients') : t('participants', 'Participants'),
        type: 'item',
        icon: UserOutlined,
        url: '/patients'
      }
    ]
  };

  const theme = useTheme();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  const { menuOrientation } = useConfig();
  const { drawerOpen } = useSelector((state) => state.menu);
  const [selectedItems, setSelectedItems] = useState<string | undefined>('');
  const [selectedLevel, setSelectedLevel] = useState<number>(0);
  const [menuItems] = useState<{ items: NavItemType[] }>({ items: authUser!!.role!! === UserRole.Clinician ? [patients] : [administration, patients] });

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  const lastItem = isHorizontal ? HORIZONTAL_MAX_ITEM : null;
  let lastItemIndex = menuItems.items.length - 1;
  let remItems: NavItemType[] = [];
  let lastItemId: string;

  //  first it checks menu item is more than giving HORIZONTAL_MAX_ITEM after that get lastItemid by giving horizontal max
  // item and it sets horizontal menu by giving horizontal max item lastly slice menuItem from array and set into remItems

  if (lastItem && lastItem < menuItems.items.length) {
    lastItemId = menuItems.items[lastItem - 1].id!;
    lastItemIndex = lastItem - 1;
    remItems = menuItems.items.slice(lastItem - 1, menuItems.items.length).map((item) => ({
      title: item.title,
      elements: item.children,
      icon: item.icon
    }));
  }

  const navGroups = menuItems.items.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup
            key={item.id}
            setSelectedItems={setSelectedItems}
            setSelectedLevel={setSelectedLevel}
            selectedLevel={selectedLevel}
            selectedItems={selectedItems}
            lastItem={lastItem!}
            remItems={remItems}
            lastItemId={lastItemId}
            item={item}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });
  return (
    <Box
      sx={{
        pt: drawerOpen ? (isHorizontal ? 0 : 2) : 0,
        '& > ul:first-of-type': { mt: 0 },
        display: isHorizontal ? { xs: 'block', lg: 'flex' } : 'block'
      }}
    >
      {navGroups}
    </Box>
  );
};

export default Navigation;
