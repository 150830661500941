

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

import useProvideClient from "hooks/client/useProvideClient";

// ==============================|| LOGO SVG ||============================== //


const mystyle = {
  width: "150px",
  margin: "10px 0px 0px 0px",
};

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const clientProvider = useProvideClient();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo} alt="Mantis" width="100" />
     *
     */
    
    <>
    <img src={"/" + clientProvider.client.logo} alt="Al loro" style={mystyle}/>
      
    </>
  );
};

export default LogoMain;
