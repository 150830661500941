// project import
import { Route, Routes } from 'react-router-dom';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';


import PrivateRoute, { PrivateRouteProps } from './components/PrivateRoute';
import LiteralsPage from './pages/admin/LiteralsPage';
import ProfilePage from './pages/settings/SettingsPage';
import LoginPage from './pages/login/LoginPage';
import UsersPage from './pages/admin/UsersPage';
import NotFoundPage from './pages/maintenance/404';
import 'app/i18n';
import PatientsPage from 'pages/patients/PatientsPage';
import DevicesPage from 'pages/admin/DevicesPage';
import OrganizationsPage from 'pages/admin/OrganizationsPage';
import NotificationsPage from 'pages/notifications/NotificationsPage';
import PatientFilePage from 'pages/patients/PatientFilePage';
import SMILEPatientFilePage from 'pages/patients/SMILE/PatientFilePage';
import VETLLARPatientFilePage from 'pages/patients/VETLLAR/PatientFilePage';
import ForgotPasswordPage from 'pages/login/ForgotPasswordPage';
import ResetPasswordPage from 'pages/login/ResetPasswordPage';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const defaultProtectedRouteProps: Omit<PrivateRouteProps, 'outlet'> = {
  authenticationPath: '/login',
};

const App = () => {
  
  return (
    <ThemeCustomization>
      {/* <RTLLayout> */}
      <ScrollTop>
          <>
            <Notistack>
              <Routes>
                <Route path='/' element={<LoginPage />} />
                <Route path='admin/devices' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<DevicesPage />} />} />
                <Route path='admin/organizations' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<OrganizationsPage />} />} />
                <Route path='admin/users' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<UsersPage />} />} />
                <Route path='admin/literals' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<LiteralsPage />} />} />
                <Route path='profile' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<ProfilePage />} />} />
                <Route path='patients' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<PatientsPage />} />} />
                <Route path='patients/:userid/file' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<PatientFilePage />} />} />
                <Route path='patients/:userid/smile/file' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<SMILEPatientFilePage />} />} />
                <Route path='patients/:userid/vetllar/file' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<VETLLARPatientFilePage />} />} />
                <Route path='notifications' element={<PrivateRoute {...defaultProtectedRouteProps} outlet={<NotificationsPage />} />} />
                <Route path='login' element={<LoginPage />} />
                <Route path='forgot-password' element={<ForgotPasswordPage />} />
                <Route path='reset-password' element={<ResetPasswordPage />} />
                <Route path='*' element={<NotFoundPage />} /> {/* Catch-all route for undefined paths */}
              </Routes>
              <Snackbar />
            </Notistack>
          </>
      </ScrollTop>
      {/* </RTLLayout> */}
    </ThemeCustomization>
  )
}

export default App;
