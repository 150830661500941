import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from "@mui/material";
import { useFormik } from 'formik';
import { useTranslation } from "react-i18next";
import * as yup from 'yup';

export type IUpdateLiteralDialogProps = {
    openDialog: boolean;
    handleClose: () => void;
    literal: any;
    handleUpdateLiteral: (literalId: number, value: string) => void;
};

export default function UpdateLiteralDialog({ openDialog, handleClose, literal, handleUpdateLiteral }: IUpdateLiteralDialogProps) {
    const { t } = useTranslation();

    const handleUpdateDialogClose: (event: any, reason: any) => void = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        handleClose();
    };

    const validationSchema = yup.object({
        key: yup.string().required(t('field_required', 'This field is required.')!!),
        value: yup.string().required(t('field_required', 'This field is required.')!!)
    });

    const formik = useFormik({
        initialValues: {
            key: literal.key!! as number,
            value: literal.value!! as string
        },
        validationSchema,
        onSubmit: (values) => {
            handleUpdateLiteral(
                literal.id,
                values.value
            )
        }
    });

    return (
        <Dialog
            open={openDialog}
            onClose={handleUpdateDialogClose}
        >
            <form noValidate onSubmit={formik.handleSubmit}>
                <DialogTitle>{t('edit_literal', 'Edit literal')}</DialogTitle>
                <DialogContent>
                    <TextField
                        id="key"
                        name="key"
                        margin="normal"
                        label={t('key', 'Key')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.key}
                        onChange={formik.handleChange}
                        error={formik.touched.key && Boolean(formik.errors.key)}
                        helperText={formik.touched.key && formik.errors.key}
                        disabled
                    />
                    
                    <TextField
                        id="value"
                        name="value"
                        margin="normal"
                        label={t('value', 'Value')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.value}
                        onChange={formik.handleChange}
                        error={formik.touched.value && Boolean(formik.errors.value)}
                        helperText={formik.touched.value && formik.errors.value}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleUpdateDialogClose(null, null)} variant="outlined" color="secondary">{t('cancel', 'Cancel')}</Button>
                    <Button type="submit" variant="outlined">{t('save', 'Save')}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}