/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 * import { ThemeMode } from 'types/config';
 *
 */

import useProvideClient from "hooks/client/useProvideClient";

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const clientProvider = useProvideClient();

  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoIconDark : logoIcon} alt="Mantis" width="100" />
     *
     */
    <img src={"/" + clientProvider.client.icon} alt="Mantis" width="40" />
    
  );
};

export default LogoIcon;
