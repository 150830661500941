import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Fragment, useMemo, useState } from "react";
import { Alert, IconButton, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Cell, Column, HeaderGroup, Row, useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { TFunction } from "i18next";
import { GlobalFilter, renderFilterTypes } from "utils/react-table";
import { DeleteTwoTone, EditTwoTone, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { EmptyTable, HeaderSort, TablePagination } from "components/third-party/ReactTable";
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { IExternalDevice } from "models/IExternalDevice";
import AddExternalDeviceDialog from "components/dialogs/ExternalDevices/AddExternalDeviceDialog";
import UpdateExternalDeviceDialog from "components/dialogs/ExternalDevices/UpdateExternalDeviceDialog";
import React from "react";
import { UserRole } from "models/IUser";
import GenericDeleteDialog from "components/dialogs/Generic/GenericDeleteDialog";
import { useAppSelector } from "app/hooks";
import { selectCurrentUser } from "features/auth/authSlice";
import { useGetExternalDevicesQuery, useGetUsersQuery, useGetOrganizationsQuery, usePostExternalDeviceMutation, usePatchExternalDeviceMutation, useDeleteExternalDeviceMutation, usePostSyknoMeasurementsMutation } from "features/api/apiSlice";
import { useTranslation } from "react-i18next";
import { getDeviceBrandName } from "helpers/ExternalDeviceExtensions";
import { DeviceBrandId } from "models/ISensor";
import UploadExternalDeviceDataDialog from "components/dialogs/ExternalDevices/UploadExternalDeviceDataDialog";

// ==============================|| DEVICES PAGE ||============================== //

export default function DevicesPage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const authUser = useAppSelector(selectCurrentUser);

  const { data: externalDevices, isLoading: isLoadingExternalDevices, isFetching: isFetchingExternalDevices } = useGetExternalDevicesQuery({})
  const { data: users, isLoading: isLoadingUsers, isFetching: isFetchingUsers } = useGetUsersQuery({ parentId: authUser!!.id!!, role: UserRole.Patient })
  const { data: organizations, isLoading: isLoadingOrganizations, isFetching: isFetchingOrganizations } = useGetOrganizationsQuery({})

  const [externalDevice, setExternalDevice] = useState<any>(null);
  const [showAddExternalDeviceDialog, setShowAddExternalDeviceDialog] = useState<boolean>(false);
  const [showUpdateExternalDeviceDialog, setShowUpdateExternalDeviceDialog] = useState<boolean>(false);
  const [showDeleteExternalDeviceDialog, setShowDeleteExternalDeviceDialog] = useState<boolean>(false);
  const [showUploadExternalDeviceDataDialog, setShowUploadExternalDeviceDataDialog] = useState<boolean>(false);

  const handleOpenAddExternalDeviceDialog = () => {
    setShowAddExternalDeviceDialog(true)
  };

  const handleCloseAddExternalDeviceDialog = () => {
    setShowAddExternalDeviceDialog(false);
  };

  const handleOpenUpdateExternalDeviceDialog = (externalDevice: IExternalDevice) => {
    setExternalDevice(externalDevice)
    setShowUpdateExternalDeviceDialog(true)
  };

  const handleCloseUpdateExternalDeviceDialog = () => {
    setShowUpdateExternalDeviceDialog(false);
    setExternalDevice(null);
  };

  const handleOpenDeleteExternalDeviceDialog = (externalDevice: IExternalDevice) => {
    setExternalDevice(externalDevice)
    setShowDeleteExternalDeviceDialog(true)
  };

  const handleCloseDeleteExternalDeviceDialog = () => {
    setShowDeleteExternalDeviceDialog(false);
    setExternalDevice(null);
  };

  const handleOpenUploadExternalDeviceDataDialog = (externalDevice: IExternalDevice) => {
    setExternalDevice(externalDevice)
    setShowUploadExternalDeviceDataDialog(true)
  };

  const handleCloseUploadExternalDeviceDataDialog = () => {
    setShowUploadExternalDeviceDataDialog(false);
    setExternalDevice(null)
  };

  const [postExternalDevice] = usePostExternalDeviceMutation();
  const handleAddExternalDevice = async (id: string, brandId: string, organizationId: number, assignedUserId: string | null, meta: string | null) => {
    await postExternalDevice({id, brandId, organizationId, assignedUserId, meta})
      .unwrap()
      .then((payload) => {
        setShowAddExternalDeviceDialog(false);
      })
      .catch((error) => {
        setErrorSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowErrorSnackbar(true)
      })
  };

  const [updateExternalDevice] = usePatchExternalDeviceMutation();
  const handleUpdateExternalDevice = async (externalDeviceId: string, brandId: string | null, organizationId: number | null, assignedUserId: string | null, meta: string | null) => {
    await updateExternalDevice({externalDeviceId, patchExternalDevice: {fields: ["BrandId", "OrganizationId", "AssignedUserId", "Meta"], brandId, lastValueDate: null, organizationId, assignedUserId, meta}})
      .unwrap()
      .then((payload) => {
        setShowAddExternalDeviceDialog(false);
      })
      .catch((error) => {
        setErrorSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowErrorSnackbar(true)
      })
  };

  const [deleteExternalDevice] = useDeleteExternalDeviceMutation();
  const handleDeleteExternalDevice = async (externalDeviceId: string) => {
    await deleteExternalDevice(externalDeviceId)
      .unwrap()
      .then((payload) => {
        setShowDeleteExternalDeviceDialog(false);
      })
      .catch((error) => {
        setErrorSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowErrorSnackbar(true)
      })
  };

  const [uploadExternalDeviceData] = usePostSyknoMeasurementsMutation();
  const handleUploadExternalDeviceData = async (deviceId: string, file: File) => {
    await uploadExternalDeviceData({deviceId, file})
      .unwrap()
      .then((payload) => {
        setShowUploadExternalDeviceDataDialog(false);
        setSuccessSnackbarMessage(t('data_upload_completed', 'The data upload has been successfully completed.'))
        setShowSuccessSnackbar(true)
      })
      .catch((error) => {
        setErrorSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowErrorSnackbar(true)
      })
  };

  const [showErrorSnackbar, setShowErrorSnackbar] = React.useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState<string | null>();

  const handleCloseErrorSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowErrorSnackbar(false);
    setErrorSnackbarMessage(null)
  };

  const [showSuccessSnackbar, setShowSuccessSnackbar] = React.useState(false);
  const [successSnackbarMessage, setSuccessSnackbarMessage] = useState<string | null>();

  const handleCloseSuccessSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSuccessSnackbar(false);
    setSuccessSnackbarMessage(null)
  };

  const columns = useMemo(
    () => [
      {
        Header: t('id', 'Id'),
        accessor: 'id'
      },
      {
        Header: t('brand', 'Brand'),
        accessor: 'brandId',
        Cell: ({ row }: { row: Row }) => {
          return (
            <Typography>{getDeviceBrandName(row.values.brandId)}</Typography>
          )
        }
      },
      {
        Header: t('organization', 'Organization'),
        accessor: 'organizationId',
        Cell: ({ row }: { row: Row }) => {
          const organization = organizations?.find(x => x.id == row.values.organizationId)

          return (
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Stack spacing={0}>
                <Typography>{organization?.name}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {organization?.id}
                </Typography>
              </Stack>
            </Stack>
          );
        }
      },
      {
        Header: t('last_value_date', 'Last value date'),
        accessor: 'lastValueDate'
      },
      {
        Header: t('assigned_user', 'Assigned user'),
        accessor: 'assignedUserId',
        Cell: ({ row }: { row: Row }) => {
          const assignedUser = users?.find(x => x.id == row.values.assignedUserId)

          return (
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Stack spacing={0}>
                <Typography>{assignedUser?.firstName} {assignedUser?.lastName}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {assignedUser?.id}
                </Typography>
              </Stack>
            </Stack>
          );
        }
      },
      {
        Header: t('meta', 'Meta'),
        accessor: 'meta'
      },
      {
        Header: t('actions', 'Actions'),
        className: 'cell-center',
        disableSortBy: true,
        Cell: ({ row }: { row: Row<{}> }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Tooltip title={t('edit', 'Edit')}>
                <IconButton
                  color="primary"
                  onClick={() => handleOpenUpdateExternalDeviceDialog(row.original)}
                >
                  <EditTwoTone twoToneColor={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
              {row.values.brandId === DeviceBrandId.Sykno && (<Tooltip title={t('upload data', 'Upload data')}>
                <IconButton
                  color="primary"
                  onClick={() => handleOpenUploadExternalDeviceDataDialog(row.original)}
                >
                  <UploadOutlined twoToneColor={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>)}
              <Tooltip title={t('delete', 'Delete')}>
                <IconButton
                  color="error"
                  onClick={() => handleOpenDeleteExternalDeviceDialog(row.original)}
                >
                  <DeleteTwoTone twoToneColor={theme.palette.error.main} />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme, users, organizations, t]
  );

  return (
    <MainCard content={false}>
      <ScrollX>
        <DevicesTable
          columns={columns}
          externalDevices={externalDevices === undefined ? [] : externalDevices}
          handleOpenAddExternalDeviceDialog={handleOpenAddExternalDeviceDialog}
          getHeaderProps={(column: HeaderGroup) => column.getSortByToggleProps()}
          t={t}
          isLoading={isLoadingExternalDevices || isLoadingUsers || isLoadingOrganizations}
          isFetching={isFetchingExternalDevices || isFetchingUsers || isFetchingOrganizations}
        />
      </ScrollX>
      {
        showDeleteExternalDeviceDialog &&
          <GenericDeleteDialog
            openDialog={showDeleteExternalDeviceDialog}
            handleClose={handleCloseDeleteExternalDeviceDialog}
            title={t('do_you_want_to_delete_x', 'Do you want to delete {0}?').replace('{0}', externalDevice.id)}
            message={t('this_action_cannot_be_undone', 'This action cannot be undone.')}
            handleDelete={() => handleDeleteExternalDevice(externalDevice.id)}
          />
      }
      
      {showAddExternalDeviceDialog && <AddExternalDeviceDialog organizations={organizations!!} users={users!!} openDialog={showAddExternalDeviceDialog} handleClose={handleCloseAddExternalDeviceDialog} handleAddExternalDevice={handleAddExternalDevice} />}
      
      {showUpdateExternalDeviceDialog && externalDevice && <UpdateExternalDeviceDialog openDialog={showUpdateExternalDeviceDialog} organizations={organizations!!} users={users!!} handleClose={handleCloseUpdateExternalDeviceDialog} handleUpdateExternalDevice={handleUpdateExternalDevice} externalDevice={externalDevice} />}
    
      {showUploadExternalDeviceDataDialog && externalDevice && <UploadExternalDeviceDataDialog openDialog={showUploadExternalDeviceDataDialog} externalDevice={externalDevice} handleClose={handleCloseUploadExternalDeviceDataDialog} handleUploadExternalDeviceData={handleUploadExternalDeviceData} />}

      <Snackbar open={showErrorSnackbar} autoHideDuration={6000} onClose={handleCloseErrorSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
        <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>{errorSnackbarMessage}</Alert>
      </Snackbar>

      <Snackbar open={showSuccessSnackbar} autoHideDuration={6000} onClose={handleCloseSuccessSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
        <Alert onClose={handleCloseSuccessSnackbar} severity="success" sx={{ width: '100%' }}>{successSnackbarMessage}</Alert>
      </Snackbar>
    </MainCard>
  )
}

// ==============================|| REACT TABLE ||============================== //

interface Props {
    columns: Column[]
    externalDevices: IExternalDevice[]
    handleOpenAddExternalDeviceDialog: () => void
    getHeaderProps: (column: HeaderGroup) => {}
    t: TFunction<"translation", undefined, "translation">
    isLoading: boolean
    isFetching: boolean
}

function DevicesTable({ columns, externalDevices, handleOpenAddExternalDeviceDialog, getHeaderProps, t, isLoading, isFetching }: Props) {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    const filterTypes = useMemo(() => renderFilterTypes, []);
    const sortBy = { id: 'fatherName', desc: false };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        gotoPage,
        setPageSize,
        state: { globalFilter, pageIndex, pageSize },
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
        columns,
        data: externalDevices,
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: ['lastValueDate', 'meta'], sortBy: [sortBy] }
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

    return (
      <Stack spacing={3}>
        <Stack
          direction={matchDownSM ? 'column' : 'row'}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 3, pb: 0 }}
          >
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            size="small"
          />
          <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={1}>
            <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleOpenAddExternalDeviceDialog} size="small" sx={{textTransform: 'none'}}>
              {t('add_device', 'Add device')}
            </Button>
          </Stack>
        </Stack>
        <Table {...getTableProps()}>
          <TableHead>
            {
              headerGroups.map((headerGroup: HeaderGroup<{}>) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {
                    headerGroup.headers.map((column: HeaderGroup) => (
                      <TableCell {...column.getHeaderProps([{ className: column.className }, getHeaderProps(column)])}>
                          <HeaderSort column={column} />
                      </TableCell>
                    ))
                  }
                </TableRow>
              ))
            }
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {
              isLoading ? (
                <EmptyTable msg={t('loading', 'Loading...')} colSpan={7} />
              ) : isFetching ? (
                <EmptyTable msg={t('fetching', 'Fetching...')} colSpan={7} />
              ) : page.length > 0 ? (
                page.map((row: Row, i: number) => {
                  prepareRow(row);

                  return (
                      <Fragment key={i}>
                      <TableRow
                          {...row.getRowProps()}
                          sx={{ cursor: 'pointer', bgcolor: 'inherit' }}
                      >
                          {row.cells.map((cell: Cell) => (
                          <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</TableCell>
                          ))}
                      </TableRow>
                      {row.isExpanded}
                      </Fragment>
                  )
                })
              ) : (
                <EmptyTable msg={t('no_data_found', 'Data not found')} colSpan={7} />
              )
            }
            <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
            <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
            </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    );
}