import { Card, Grid, useTheme } from "@mui/material";
import { getSensorTypeName } from "helpers/ExternalDeviceExtensions";
import { ISensorDataExtended } from "models/ISensor";
import moment from "moment";

const SensorValueCard = ({ sensorDataExtended }: { sensorDataExtended: ISensorDataExtended }) => {
    const theme = useTheme();

    return(
        <>
            <Card
                sx={{
                    border: '1px solid',
                    borderRadius: 1,
                    borderColor: theme.palette.divider,
                    boxShadow: 'inherit',
                    padding: '5px'
                }}
              >
                <Grid container spacing={1} direction="column" alignItems="center">
                    <Grid item lineHeight="normal">{getSensorTypeName(sensorDataExtended.typeId)}</Grid>
                    <Grid item lineHeight="normal" fontWeight="bold">
                    {sensorDataExtended.lastValue % 1 === 0 // Check if the number has decimals
                        ? sensorDataExtended.lastValue // If no decimals, display as is
                        : sensorDataExtended.lastValue.toFixed(2) // If decimals, truncate to two decimals
                    } {sensorDataExtended.units}    
                    </Grid>
                    <Grid item lineHeight="normal" color="grey">{moment(sensorDataExtended.lastValueDate.toString()).locale('es').format('l LTS')}</Grid>
                </Grid>
            </Card>
        </>
    )
}

export default SensorValueCard;