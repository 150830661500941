import { createContext } from "react";

export interface IClient {
  title: string
  domain: string
  provider: string
  backgroundLoginLeft: string
  logo: string
  icon: string
  favicon: string
  description: string
  features: string[]
}

export interface IClientContextProps {
  client: IClient
}

const ClientContext: React.Context<IClientContextProps> = createContext(
  null as unknown as IClientContextProps
);

export default ClientContext;