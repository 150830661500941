import { useState, useEffect } from 'react';

// material-ui
import {
  Grid,
  Stack,
  useMediaQuery,
  Theme,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  Slide,
  Pagination,
  Typography
} from '@mui/material';

// project import
import EmptyUserCard from 'components/cards/skeleton/EmptyUserCard';
import PatientCard from 'components/cards/PatientCard';

import { GlobalFilter } from 'utils/react-table';

// types
import { IUser, UserRole } from 'models/IUser';

// assets
import { useAppSelector } from 'app/hooks';
import { useGetUsersQuery } from 'features/api/apiSlice';
import { selectCurrentUser } from 'features/auth/authSlice';
import { useTranslation } from 'react-i18next';

// ==============================|| CUSTOMER - CARD ||============================== //

const PatientsPage = () => {
  const { t } = useTranslation();
  const matchDownSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const authUser = useAppSelector(selectCurrentUser);
  const { data: users, isLoading, isFetching } = useGetUsersQuery({ parentId: authUser!!.id!!, role: UserRole.Patient })

  const [sortBy, setSortBy] = useState(t('last_name', 'Last name')!!);
  const [globalFilter, setGlobalFilter] = useState('');
  const [usersToShow, setUsersToShow] = useState<IUser[]>([]);
  const [page, setPage] = useState(1);
  const handleSortBy = (event: SelectChangeEvent) => {
    handleChangePage(null, 1)
    setSortBy(event.target.value as string);
  };

  // search
  useEffect(() => {
    if (users !== undefined && users !== null) {
      const newData = users.filter((value: any) => {
        if (globalFilter) {
          return value.firstName.toLowerCase().includes(globalFilter.toLowerCase())
            || value.lastName.toLowerCase().includes(globalFilter.toLowerCase());
        } else {
          return value;
        }
      });
      setUsersToShow(newData);
    }
  }, [globalFilter, users]);

  const patientsPerPage = 8;
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(patientsPerPage);

  const count = Math.ceil(usersToShow.length / patientsPerPage);

  const handleChangePage = (event: any, page: any) => {
    setPage(page);
    setMinValue((page - 1) * patientsPerPage);
    setMaxValue(page * patientsPerPage);
  };

  const allColumns = [
    {
      id: 1,
      header: t('last_name', 'Last name')
    },
    {
      id: 2,
      header: t('first_name', 'First name')
    },
    {
      id: 3,
      header: t('enrolment', 'Enrolment')
    },
    {
      id: 4,
      header: t('last_measurement', 'Last measurement')
    }
  ];

  if (isLoading) {
    return (
      <Grid container spacing={3}>
          <EmptyUserCard title={t('loading', 'Loading...')} />
      </Grid>
    )
  } else if (isFetching) {
    return (
      <Grid container spacing={3}>
          <EmptyUserCard title={t('fetching', 'Fetching...')} />
      </Grid>
    )
  } else {
    return (
      <>
        <Box sx={{ position: 'relative', marginBottom: 3 }}>
          <Stack direction="row" alignItems="center">
            <Stack
              direction={matchDownSM ? 'column' : 'row'}
              sx={{ width: '100%' }}
              spacing={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <GlobalFilter preGlobalFilteredRows={usersToShow as any} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
              <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={1}>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={sortBy}
                    onChange={handleSortBy}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    renderValue={(selected) => {
                      if (!selected)
                        return <Typography variant="subtitle1">{t('sort_by', 'Sort by')}</Typography>;
  
                      return <Typography variant="subtitle2">{t('sort_by', 'Sort by')} ({sortBy})</Typography>;
                    }}
                  >
                    {allColumns.map((column) => {
                      return (
                        <MenuItem key={column.id} value={column.header}>
                          {column.header}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Grid container spacing={3}>
          {usersToShow.length > 0 ? (
            usersToShow
              .sort(function (a: any, b: any) {
                if (sortBy === t('first_name', 'First name')) return a.firstName.localeCompare(b.firstName);
                if (sortBy === t('last_name', 'Last name')) return a.lastName.localeCompare(b.lastName);
                if (sortBy === t('enrolment', 'Enrolment')) return a.enrolmentDate?.localeCompare(b.enrolmentDate);
                if (sortBy === t('last_measurement', 'Last measurement')) return a.lastValueDate?.localeCompare(b.lastValueDate);
                return a;
              })
              .slice(minValue, maxValue)
              .map((user: IUser, index: number) => (
                <Slide key={index} direction="up" in={true} timeout={50}>
                  <Grid item xs={12} sm={5} lg={3}>
                    <PatientCard patient={user} />
                  </Grid>
                </Slide>
              ))
          ) : (
            <EmptyUserCard title={t('no_users_to_show', 'There are no users to show.')} />
          )}
        </Grid>
        <Stack spacing={2} sx={{ p: 2.5 }} alignItems="flex-end">
          <Pagination
            count={count}
            size="medium"
            page={page}
            showFirstButton
            showLastButton
            variant="combined"
            color="primary"
            onChange={handleChangePage}
          />
        </Stack>
      </>
    );
  }
};

export default PatientsPage;
