import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from "@mui/material";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DateTimeField, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { useTranslation } from "react-i18next";

export type IAddAnnotationDialogProps = {
    openDialog: boolean;
    handleClose: () => void;
    handleAddAnnotation: (timelineDate: string, text: string) => void;
    timelineDate: Date | null;
};

export default function AddAnnotationDialog({ openDialog, handleClose, handleAddAnnotation, timelineDate }: IAddAnnotationDialogProps) {
    const { t } = useTranslation();

    const handleAddDialogClose: (event: any, reason: any) => void = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        handleClose();
    };

    const validationSchema = yup.object({
        timelineDate: yup.string().required(t('field_required', 'This field is required.')!!),
        text: yup.string().required(t('field_required', 'This field is required.')!!)
    });

    const formik = useFormik({
        initialValues: {
            timelineDate: timelineDate ? dayjs(timelineDate) : null,
            text: ''
        },
        validationSchema,
        onSubmit: (values) => {
            handleAddAnnotation(
                moment.utc(values.timelineDate!!.toString()).format('YYYY-MM-DDTHH:mm:ss Z'),
                values.text
            )
        }
    });

    return (
        <Dialog
            open={openDialog}
            onClose={handleAddDialogClose}
        >
            <form noValidate onSubmit={formik.handleSubmit}>
                <DialogTitle>{t('add_annotation', 'Add annotation')}</DialogTitle>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                        <DateTimeField
                            id="timelineDate"
                            name="timelineDate"
                            margin="normal"
                            label={t('timeline_date', 'Timeline date')}
                            fullWidth
                            variant="outlined"
                            required
                            value={formik.values.timelineDate}
                            onChange={
                                (value) => {
                                    formik.setFieldValue('timelineDate', value);
                                }
                            }
                            format="L HH:mm:ss"
                        />
                    </LocalizationProvider>
                    <TextField
                        id="text"
                        name="text"
                        margin="normal"
                        label={t('text', 'Text')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.text}
                        onChange={formik.handleChange}
                        error={formik.touched.text && Boolean(formik.errors.text)}
                        helperText={formik.touched.text && formik.errors.text}
                        multiline
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleAddDialogClose(null, null)} variant="outlined" color="secondary">{t('cancel', 'Cancel')}</Button>
                    <Button type="submit" variant="outlined">{t('save', 'Save')}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}