import { DeviceBrandId, DeviceBrandName, SensorTypeId, SensorTypeName } from "models/ISensor";

export const CHAMPTelemetries = {
        [`[${DeviceBrandName.Fitbit}] ${SensorTypeName.HeartRate}`]: `${DeviceBrandId.Fitbit}:${SensorTypeId.HeartRate}`,
        [`[${DeviceBrandName.Fitbit}] ${SensorTypeName.BreathingRate}`]: `${DeviceBrandId.Fitbit}:${SensorTypeId.BreathingRate}`,
        [`[${DeviceBrandName.Fitbit}] ${SensorTypeName.HRV}`]: `${DeviceBrandId.Fitbit}:${SensorTypeId.HRV}`,
        [`[${DeviceBrandName.Fitbit}] ${SensorTypeName.SPO2}`]: `${DeviceBrandId.Fitbit}:${SensorTypeId.SPO2}`,

        [`[${DeviceBrandName.Flow}] ${SensorTypeName.PM1}`]: `${DeviceBrandId.Flow}:${SensorTypeId.PM1}`,
        [`[${DeviceBrandName.Flow}] ${SensorTypeName.PM10}`]: `${DeviceBrandId.Flow}:${SensorTypeId.PM10}`,
        [`[${DeviceBrandName.Flow}] ${SensorTypeName.PM25}`]: `${DeviceBrandId.Flow}:${SensorTypeId.PM25}`,
        [`[${DeviceBrandName.Flow}] ${SensorTypeName.NO2}`]: `${DeviceBrandId.Flow}:${SensorTypeId.NO2}`,
        [`[${DeviceBrandName.Flow}] ${SensorTypeName.VOC}`]: `${DeviceBrandId.Flow}:${SensorTypeId.VOC}`,

        [`[${DeviceBrandName.WAQI}] ${SensorTypeName.PM10}`]: `${DeviceBrandId.WAQI}:${SensorTypeId.PM10}`,
        [`[${DeviceBrandName.WAQI}] ${SensorTypeName.PM25}`]: `${DeviceBrandId.WAQI}:${SensorTypeId.PM25}`,
        [`[${DeviceBrandName.WAQI}] ${SensorTypeName.NO2}`]: `${DeviceBrandId.WAQI}:${SensorTypeId.NO2}`,
        [`[${DeviceBrandName.WAQI}] ${SensorTypeName.Pressure}`]: `${DeviceBrandId.WAQI}:${SensorTypeId.Pressure}`,
        [`[${DeviceBrandName.WAQI}] ${SensorTypeName.Temperature}`]: `${DeviceBrandId.WAQI}:${SensorTypeId.Temperature}`,
        [`[${DeviceBrandName.WAQI}] ${SensorTypeName.Humidity}`]: `${DeviceBrandId.WAQI}:${SensorTypeId.Humidity}`,
        [`[${DeviceBrandName.WAQI}] ${SensorTypeName.CO}`]: `${DeviceBrandId.WAQI}:${SensorTypeId.CO}`,
        [`[${DeviceBrandName.WAQI}] ${SensorTypeName.O3}`]: `${DeviceBrandId.WAQI}:${SensorTypeId.O3}`,
        [`[${DeviceBrandName.WAQI}] ${SensorTypeName.SO2}`]: `${DeviceBrandId.WAQI}:${SensorTypeId.SO2}`,

        [`[${DeviceBrandName.CapMedic}] ${SensorTypeName.PEF}`]: `${DeviceBrandId.CapMedic}:${SensorTypeId.PEF}`,
        [`[${DeviceBrandName.CapMedic}] ${SensorTypeName.FEV1}`]: `${DeviceBrandId.CapMedic}:${SensorTypeId.FEV1}`,
        [`[${DeviceBrandName.CapMedic}] ${SensorTypeName.PUFF}`]: `${DeviceBrandId.CapMedic}:${SensorTypeId.PUFF}`,

        [`[${DeviceBrandName.WoePal}] ${SensorTypeName.Humidity}`]: `${DeviceBrandId.WoePal}:${SensorTypeId.Humidity}`,
        [`[${DeviceBrandName.WoePal}] ${SensorTypeName.NO2}`]: `${DeviceBrandId.WoePal}:${SensorTypeId.NO2}`,
        [`[${DeviceBrandName.WoePal}] ${SensorTypeName.PM25}`]: `${DeviceBrandId.WoePal}:${SensorTypeId.PM25}`,
        [`[${DeviceBrandName.WoePal}] ${SensorTypeName.Pressure}`]: `${DeviceBrandId.WoePal}:${SensorTypeId.Pressure}`,
        [`[${DeviceBrandName.WoePal}] ${SensorTypeName.Temperature}`]: `${DeviceBrandId.WoePal}:${SensorTypeId.Temperature}`
};