import { Box, Typography, Tab, Tabs } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import MainCard from "components/MainCard";
import TabPersonal from "pages/settings/tabs/TabProfile";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import ChangePasswordData from "pages/settings/tabs/TabChangePassword";
  
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SettingsPage() {
    const { t } = useTranslation();

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    return (
        <MainCard>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%', marginBottom: 3 }}>
                <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="account profile tab">
                    <Tab label={t('profile', 'Profile')} icon={<UserOutlined />} iconPosition="start" sx={{textTransform: 'none'}} {...a11yProps(0)}/>
                    <Tab label={t('change_password', 'Change password')} icon={<LockOutlined />} iconPosition="start" sx={{textTransform: 'none'}} {...a11yProps(1)}/>
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <TabPersonal/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <ChangePasswordData/>
            </CustomTabPanel>
        </MainCard>
    );
}