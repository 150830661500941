import { DeviceBrandName, SensorTypeName, DeviceBrandId, SensorTypeId } from "models/ISensor";

export const VETLLARTelemetries = {
        [`[${DeviceBrandName.CEMIC}] ${SensorTypeName.FormaldehydeCounts}`]: `${DeviceBrandId.CEMIC}:${SensorTypeId.FormaldehydeCounts}`,
        [`[${DeviceBrandName.CEMIC}] ${SensorTypeName.FormaldehydeDiffCounts}`]: `${DeviceBrandId.CEMIC}:${SensorTypeId.FormaldehydeDiffCounts}`,
        [`[${DeviceBrandName.CEMIC}] ${SensorTypeName.Formaldehyde}`]: `${DeviceBrandId.CEMIC}:${SensorTypeId.Formaldehyde}`,
        [`[${DeviceBrandName.CEMIC}] ${SensorTypeName.CO2Counts}`]: `${DeviceBrandId.CEMIC}:${SensorTypeId.CO2Counts}`,
        [`[${DeviceBrandName.CEMIC}] ${SensorTypeName.CO2}`]: `${DeviceBrandId.CEMIC}:${SensorTypeId.CO2}`,
        [`[${DeviceBrandName.CEMIC}] ${SensorTypeName.Humidity}`]: `${DeviceBrandId.CEMIC}:${SensorTypeId.Humidity}`,
        [`[${DeviceBrandName.CEMIC}] ${SensorTypeName.Temperature}`]: `${DeviceBrandId.CEMIC}:${SensorTypeId.Temperature}`
};