import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from "@mui/material";
import { useFormik } from 'formik';
import { useTranslation } from "react-i18next";
import * as yup from 'yup';

export type IAddLiteralDialogProps = {
    openDialog: boolean;
    handleClose: () => void;
    handleAddLiteral: (key: string, value: string) => void;
};

export default function AddLiteralDialog({ openDialog, handleClose, handleAddLiteral }: IAddLiteralDialogProps) {
    const { t } = useTranslation();

    const handleAddDialogClose: (event: any, reason: any) => void = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        handleClose();
    };

    const validationSchema = yup.object({
        key: yup.string().required(t('field_required', 'This field is required.')!!),
        value: yup.string().required(t('field_required', 'This field is required.')!!)
    });

    const formik = useFormik({
        initialValues: {
            key: '',
            value: ''
        },
        validationSchema,
        onSubmit: (values) => {
            handleAddLiteral(
                values.key,
                values.value
            )
        }
    });

    return (
        <Dialog
            open={openDialog}
            onClose={handleAddDialogClose}
        >
            <form noValidate onSubmit={formik.handleSubmit}>
                <DialogTitle>{t('add_literal', 'Add literal')}</DialogTitle>
                <DialogContent>
                    <TextField
                        id="key"
                        name="key"
                        autoFocus
                        margin="normal"
                        label={t('key', 'Key')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.key}
                        onChange={formik.handleChange}
                        error={formik.touched.key && Boolean(formik.errors.key)}
                        helperText={formik.touched.key && formik.errors.key}
                    />

                    <TextField
                        id="value"
                        name="value"
                        margin="normal"
                        label={t('value', 'Value')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.value}
                        onChange={formik.handleChange}
                        error={formik.touched.value && Boolean(formik.errors.value)}
                        helperText={formik.touched.value && formik.errors.value}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleAddDialogClose(null, null)} variant="outlined" color="secondary">{t('cancel', 'Cancel')}</Button>
                    <Button type="submit" variant="outlined">{t('save', 'Save')}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}