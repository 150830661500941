import { Alert, Box, Button, LinearProgress, Paper, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import LanguageSelector from 'components/selectors/LanguageSelector';
import { useAppSelector } from 'app/hooks';
import { useSendResetPasswordCodeMutation } from 'features/api/apiSlice';
import { selectCurrentUser } from 'features/auth/authSlice';
import useProvideClient from 'hooks/client/useProvideClient';

export default function ForgotPasswordPage() {
  const clientProvider = useProvideClient();
  const { t } = useTranslation();

  const [sendResetPasswordCode] = useSendResetPasswordCodeMutation();
  const navigate = useNavigate();
  const authUser = useAppSelector(selectCurrentUser);

  const [email, setEmail] = useState("");

  const [isSendingResetPasswordCode, setIsSendingResetPasswordCode] = useState(false);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);

  if (authUser) {
    return <Navigate to="/patients" />; // redirect to profile page if already logged in
  }

  const handleEmailChange: (event: any) => void = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit: (event: any) => void = async e => {
    e.preventDefault()

    setIsSendingResetPasswordCode(true)
    await sendResetPasswordCode({email})
    .unwrap()
    .then((result) => {
      setIsSendingResetPasswordCode(false)
      setShowError(false)
      navigate("/reset-password?email="+email)
    })
    .catch((error) => {
      setIsSendingResetPasswordCode(false)
      setError(t('something_went_wrong', 'Something went wrong.')!!)
      setShowError(true);
    })
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage: `url("data:image/svg+xml;base64,${btoa(clientProvider.client.backgroundLoginLeft)}")`,
          backgroundRepeat: 'repeat',
          backgroundSize: 'inherit',
          backgroundPosition: 'center'
        }}
      />
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
        <Stack sx={{ position: "fixed", top: '2%', right: '1%' }}>
          <LanguageSelector />
        </Stack>
        <Stack
          width='100%'
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box component="img" src={clientProvider.client.logo} maxWidth='30%' sx={{ my: 2 }} />

          {isSendingResetPasswordCode ?
            <LinearProgress sx={{ width: "60%" }} />
            :
            <>
              <Typography component="h1" variant="h6" color="#b8bacc">
                {t("enter_email_change_password", "Enter your email to request a code to change your password.")}
              </Typography>
              <Stack component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, height: '100%', width: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TextField
                  variant='standard'
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t("email", "Email")}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={handleEmailChange}
                />

                {showError &&
                  <Alert sx={{ width: '100%', my: 2 }} severity="error">
                    {error}
                  </Alert>
                }

                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                  disabled={!email}
                >
                  {t("request_code", "Request code")}
                </Button>
              </Stack>
            </>
          }
        </Stack>
      </Grid>
    </Grid>
  );
}
