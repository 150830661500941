import { ISensorData, ISensorDataExtended } from "./ISensor";

export interface IUser {
    id?: string
    provider?: string
    firstName?: string
    lastName?: string
    email?: string
    culture?: string
    role?: UserRole
    parentId?: string
    createdAt?: Date
    lastValueDate?: Date
    enrolmentDate?: Date
    devicesData?: IDeviceData[]
    aiTags?: IAITag[]
    meta?: string
    initials?: string
}

export enum UserRole {
    Admin,
    Manager,
    Clinician,
    Patient
}

export interface IDeviceData {
    brandId: string
    sensorsData: ISensorData[]
}

export interface IDeviceDataExtended {
    brandId: string
    sensorsDataExtended: ISensorDataExtended[]
}

export enum UserProviderId {
    CHAMP = "CHAMP",
    KORE = "KORE",
    AIQReady = "AIQReady",
    SMILE = "SMILE",
    VETLLAR = "VETLLAR",
    AURA = "AURA",
    ICARE = "ICARE"
}

export enum UserProviderName {
    CHAMP = "CHAMP",
    KORE = "KORE",
    AIQReady = "A-IQ Ready",
    SMILE = "SMILE",
    VETLLAR = "VETLLAR",
    AURA = "AURA",
    ICARE = "ICARE"
}

export interface IAITag {
    literalKey: string
    color: string
}