import Highcharts, { SeriesFlagsOptions } from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { useState, useEffect, useRef } from "react";
import annotations from "highcharts/modules/annotations";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import noDataToDisplay from "highcharts/modules/no-data-to-display";
import accessibility from "highcharts/modules/accessibility";
import { Box } from "@mui/material";
import "./TimeSeriesChart.css";
import { UserRole } from "models/IUser";
import { getUserRoleName } from "helpers/UserExtensions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { SensorTypeName } from "models/ISensor";
import { getAnxietyLevel, getDepressionLevel } from "helpers/SMILEExtensions";

annotations(Highcharts);
exporting(Highcharts);
exportData(Highcharts);
noDataToDisplay(Highcharts);
accessibility(Highcharts);

interface CustomPointOptionsObject extends Highcharts.PointOptionsObject {
  units?: string;
}

export type TimeSeriesValue = {
  id: string;
  name: string;
  date: Date;
  value: number;
  units: string | undefined;
};

export type TimeSeriesAnnotation = {
  userRole: UserRole;
  date: Date;
  description: string;
};

type TimeSeriesChartProps = {
  showLoading: boolean;
  values: TimeSeriesValue[] | undefined;
  annotations: TimeSeriesAnnotation[] | undefined;
  handleOpenAddAnnotationDialogWithTimelineDate: (timelineDate: Date) => void;
};

const groupBy = <K, V>(list: Array<V>, keyGetter: (input: V) => K) => {
  const map = new Map<K, Array<V>>();
  list.forEach((item: any) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

const TimeSeriesChart = ({
  showLoading,
  values,
  annotations,
  handleOpenAddAnnotationDialogWithTimelineDate
}: TimeSeriesChartProps) => {
  const { t } = useTranslation();
  
  const HighchartsOptions: Highcharts.Options = {
    mapNavigation: {
      enableMouseWheelZoom: true,
      mouseWheelSensitivity: 1.1,
    },
    chart: {
      renderTo: "timeserieschartcontainer",
      zooming: {
        key: "alt",
        pinchType: "x",
        singleTouch: false,
        type: "x",
      },
      panning: {
        enabled: true,
        type: "x",
      },
      panKey: "shift",
    },
    rangeSelector: {
      enabled: false,
    },
    plotOptions: {
      series: {
        showInNavigator: true,
        states: {
          inactive: {
            enabled: false,
          },
        },
        point: {
          events: {
            click: (e) => {
              var allowedDateFormats = ['dddd, DD MMM, HH:mm-HH:mm', 'dddd, DD MMM, HH:mm:ss'];
              const dateRange =  moment((e.point as any).key!!, allowedDateFormats)
              handleOpenAddAnnotationDialogWithTimelineDate(dateRange.toDate())
            }
          }
        }
      },
      flags: {
        tooltip: {
          headerFormat: "<b>" + t('date', 'Date') + ":</b> {point.x:%m-%d-%Y %H:%M:%S}<br><br>",
          pointFormatter: function (this: Highcharts.Point) {
            return (this as any).text;
          }
        }
      }
    },
    xAxis: {
      type: "datetime",
      title: {
        text: t('date', 'Date'),
      },
    },
    yAxis: {
      title: {
        text: t('values', 'Values'),
      },
      allowDecimals: true,
    },
    lang: {
      noData: t('no_data_found', 'Data not found.')!!,
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#303030",
      },
    },
    tooltip: {
      headerFormat: "<b>" + t('date', 'Date') + ":</b> {point.x:%d/%m/%Y %H:%M:%S}<br>",
      pointFormatter: function () {
        const point = this as unknown as CustomPointOptionsObject;
        if (this.series.name.includes(SensorTypeName.AnxietyLevel)) {
          return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: ' + getAnxietyLevel(point.y!!) + ' ' + point.units + '<br>';
        }
        else if (this.series.name.includes(SensorTypeName.DepressionLevel)) {
          return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: ' + getDepressionLevel(point.y!!) + ' ' + point.units + '<br>';
        }
        else {
          return '<span style="color:' + this.series.color + '">' + this.series.name + '</span>: ' + point.y?.toFixed(2) + ' ' + point.units + '<br>';
        }
      },
      split: false,
      shared: true,
    },
    legend: {
      enabled: true,
    },
    credits: {
      enabled: false,
    },
    time: {
      //timezoneOffset: new Date().getTimezoneOffset()
      useUTC: false,
    }
  };

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [highchartsOption, setHighchartsOption] = useState<Highcharts.Options>(HighchartsOptions);

  useEffect(() => {
    if (values && values.filter((c) => c.date).length > 0) {
      const seriesOptions: Highcharts.SeriesOptionsType[] = [];
      
      if (annotations && annotations.length > 0) {
        const flags = annotations.map((annotation) => {
          return {
            x: new Date(annotation.date).getTime(),
            text: annotation.description,
            title: Array.from(getUserRoleName(annotation.userRole))[0],
          } as Highcharts.PointOptionsObject;
        });
        const flagSeriesOptions: SeriesFlagsOptions = {
          type: "flags",
          name: "Highcharts",
          shape: "circlepin",
          data: flags,
          showInLegend: false,
          color: "#333333"
        };
        seriesOptions.push(flagSeriesOptions);
      }

      const groupedMap = groupBy<string, TimeSeriesValue>(
        values,
        (value: TimeSeriesValue) => value.name
      );

      for (let [k, v] of groupedMap) {
        const data = v.map((c) => [c.date.getTime(), c.value, c.units]);
        const seriesOption: Highcharts.SeriesOptionsType = {
          type: "line",
          name: k,
          data: data,
          showInLegend: true,
          keys: ["x", "y", "units"]
        };
        seriesOptions.push(seriesOption);
      }

      setHighchartsOption({ ...HighchartsOptions, series: seriesOptions });
      chartComponentRef.current?.chart.redraw();
    } else {
      if (chartComponentRef.current?.chart.series.length === undefined || chartComponentRef.current?.chart.series.length > 0) {
        setHighchartsOption({ ...HighchartsOptions, series: [] });
        chartComponentRef.current?.chart.redraw();
        chartComponentRef.current?.chart.xAxis[0].setExtremes()
      }
    }
  }, [values, annotations, t]);

  useEffect(() => {
    if (chartComponentRef?.current) {
      if (showLoading) {
        chartComponentRef.current.chart.showLoading("");
      } else {
        chartComponentRef.current.chart.hideLoading();
      }
      chartComponentRef.current.chart.reflow();
    }
  }, [showLoading, t]);

  return (
    <Box id="timeserieschartcontainer">
      <HighchartsReact
        ref={chartComponentRef}
        highcharts={Highcharts}
        constructorType={"stockChart"}
        options={highchartsOption}
      />
    </Box>
  );
};

export default TimeSeriesChart;
