import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, IconButton, InputAdornment, MenuItem, FormControl, InputLabel, Select, Autocomplete, Box, Stack, Typography, FormHelperText } from "@mui/material";
import { useEffect, useState } from "react";
import { IUser, UserProviderId, UserRole } from "models/IUser";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { getSonUserRoles, getUserProviderName, getUserRoleName } from "helpers/UserExtensions";
import { useTranslation } from "react-i18next";

export type IAddUserDialogProps = {
    openDialog: boolean;
    authenticatedUser: IUser;
    users: IUser[];
    handleClose: () => void;
    handleAddUser: (firstName: string, lastName: string, email: string, password: string, parentId: string, role: UserRole, culture: string | null, enrolmentDate: string | null, provider: string | null, meta: string | null) => void;
};

export default function AddUserDialog({ openDialog, authenticatedUser, users, handleClose, handleAddUser }: IAddUserDialogProps) {
    const { t } = useTranslation();

    const [filteredUsers, setFilteredUsers] = useState(users!!.filter(x => x.role != UserRole.Patient));
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);

    const handleClickShowPassword: () => void = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword: (event: any) => void = (event) => {
        event.preventDefault();
    };

    const handleClickShowRepeatPassword: () => void = () => {
        setShowRepeatPassword(!showRepeatPassword);
    };

    const handleMouseDownrepeatPassword: (event: any) => void = (event) => {
        event.preventDefault();
    };

    const handleAddDialogClose: (event: any, reason: any) => void = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        handleClose();
    };

    const validationSchema = yup.object({
        firstName: yup.string().required(t('field_required', 'This field is required.')!!),
        lastName: yup.string().required(t('field_required', 'This field is required.')!!),
        email: yup.string().email(t('wrong_email_format', 'Wrong email format.')!!).required(t('field_required', 'This field is required.')!!),
        password: yup.string().required(t('field_required', 'This field is required.')!!)
            .matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                t('password_requirements', 'Password must contain at least 8 characters, one uppercase, one number and one special case character.')!!
            ),
        repeatPassword: yup.string().required(t('field_required', 'This field is required.')!!)
            .test('confirm', t('passwords_dont_match', 'Passwords don\'t match.')!!, (confirm: string, yup: any) => yup.parent.password === confirm),
        provider: authenticatedUser.role === UserRole.Admin ? yup.string().required(t('field_required', 'This field is required.')!!) : yup.string().notRequired(),
        parent: yup.object().required(t('field_required', 'This field is required.')!!),
        role: yup.number().required(t('field_required', 'This field is required.')!!)
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            repeatPassword: '',
            provider: '',
            parent: null as IUser | null,
            role: '',
            enrolmentDate: null as dayjs.Dayjs | null
        },
        validationSchema,
        onSubmit: (values) => {
            handleAddUser(
                values.firstName,
                values.lastName,
                values.email,
                values.password,
                values.parent!!.id!!,
                parseInt(values.role),
                null,
                values.enrolmentDate == null ? null : moment.utc(values.enrolmentDate.toString()).format('YYYY-MM-DDTHH:mm:ss Z'),
                values.provider == '' ? null : values.provider,
                null
            )
        }
    });

    useEffect(() => {
        // Reset enrolmentDate when role changes or when the component mounts
        formik.setFieldValue('enrolmentDate', null);
    }, [formik.values.role]);

    useEffect(() => {
        const updatedFilteredUsers = users.filter(user => user.role !== UserRole.Patient
            && (authenticatedUser.role === UserRole.Admin ? user.provider === formik.values.provider || user.provider === authenticatedUser.provider : user.provider === authenticatedUser.provider));
        setFilteredUsers(updatedFilteredUsers);

        formik.setFieldValue('parent', null);
    }, [formik.values.provider, users]);

    return (
        <Dialog
            open={openDialog}
            onClose={handleAddDialogClose}
        >
            <form noValidate onSubmit={formik.handleSubmit}>
                <DialogTitle>{t('add_user', 'Add user')}</DialogTitle>
                <DialogContent>
                    <TextField
                        id="firstName"
                        name="firstName"
                        autoFocus
                        margin="normal"
                        label={t('first_name', 'First name')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                    />

                    <TextField
                        id="lastName"
                        name="lastName"
                        margin="normal"
                        label={t('last_name', 'Last name')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                    />

                    <TextField
                        id="email"
                        name="email"
                        margin="normal"
                        label={t('email', 'Email')}
                        type="email"
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                    
                    <TextField
                        id="password"
                        name="password"
                        margin="normal"
                        label={t('password', 'Password')}
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    
                    <TextField
                        id="repeatPassword"
                        name="repeatPassword"
                        margin="normal"
                        label={t('confirm_password', 'Repeat password')}
                        type={showRepeatPassword ? "text" : "password"}
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.repeatPassword}
                        onChange={formik.handleChange}
                        error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                        helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowRepeatPassword}
                                        onMouseDown={handleMouseDownrepeatPassword}
                                        edge="end"
                                    >
                                        {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />

                    {
                        authenticatedUser.role === UserRole.Admin &&
                        <FormControl margin="normal" fullWidth>
                            <InputLabel 
                                id="provider-select-label"
                                error={formik.touched.provider && Boolean(formik.errors.provider)}
                                required>
                                    {t('provider', 'Provider')}
                            </InputLabel>
                            <Select
                                id="provider"
                                name="provider"
                                labelId="provider-select-label"
                                label={t('provider', 'Provider')}
                                value={formik.values.provider}
                                onChange={formik.handleChange}
                            >
                                <MenuItem value={UserProviderId.CHAMP}>{getUserProviderName(UserProviderId.CHAMP)}</MenuItem>
                                <MenuItem value={UserProviderId.KORE}>{getUserProviderName(UserProviderId.KORE)}</MenuItem>
                                <MenuItem value={UserProviderId.AIQReady}>{getUserProviderName(UserProviderId.AIQReady)}</MenuItem>
                                <MenuItem value={UserProviderId.SMILE}>{getUserProviderName(UserProviderId.SMILE)}</MenuItem>
                                <MenuItem value={UserProviderId.VETLLAR}>{getUserProviderName(UserProviderId.VETLLAR)}</MenuItem>
                                <MenuItem value={UserProviderId.AURA}>{getUserProviderName(UserProviderId.AURA)}</MenuItem>
                                <MenuItem value={UserProviderId.ICARE}>{getUserProviderName(UserProviderId.ICARE)}</MenuItem>
                            </Select>
                            {formik.touched.provider && formik.errors.provider && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {' '}
                                    {formik.errors.provider}{' '}
                                </FormHelperText>
                            )}
                        </FormControl>
                    }

                    <FormControl margin="normal" fullWidth>
                        <Autocomplete
                            fullWidth
                            id="parentId-autocomplete-label"
                            options={filteredUsers!!}
                            value={formik.values.parent}
                            onChange={(event, parent) => {
                                formik.setFieldValue('parent', parent)
                                if (parent == null)
                                    formik.setFieldValue('role', '')
                            }}
                            getOptionLabel={(parent) => {
                                if (filteredUsers) {
                                    const exist = filteredUsers.includes(parent)
                                    if (exist)
                                        return `${parent.firstName} ${parent.lastName}`
                                }
                                return ''
                            }}
                            renderInput={(params) => 
                                <TextField
                                    {...params}
                                    name="parent"
                                    error={formik.touched.parent && Boolean(formik.errors.parent)}
                                    helperText={(formik.touched.parent && formik.errors.parent) ? <>{formik.errors.parent}</> : null}
                                    label={t('parent', 'Parent')}
                                    required
                                />
                            }
                            renderOption={(props, parent) => (
                                <Box component="li" {...props}>
                                    <Stack direction="row" spacing={1.5} alignItems="center">
                                        <Stack spacing={0}>
                                            <Typography>{parent?.firstName} {parent?.lastName}</Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                {parent?.id}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                            )}
                        />
                    </FormControl>
                    
                    <FormControl margin="normal" fullWidth>
                        <InputLabel 
                            id="role-select-label"
                            error={formik.touched.role && Boolean(formik.errors.role)}
                            required>
                                {t('role', 'Role')}
                        </InputLabel>
                        <Select
                            id="role"
                            name="role"
                            labelId="role-select-label"
                            label={t('role', 'Role')}
                            value={formik.values.role}
                            onChange={formik.handleChange}
                        >
                            {
                                formik.values.parent && 
                                getSonUserRoles(formik.values.parent!!.role!!).map(role =>
                                    <MenuItem value={role} key={role}>{getUserRoleName(role)}</MenuItem>
                                )
                            }
                        </Select>
                        {formik.touched.role && formik.errors.role && (
                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                {' '}
                                {formik.errors.role}{' '}
                            </FormHelperText>
                        )}
                    </FormControl>

                    {
                        parseInt(formik.values.role) === UserRole.Patient && (
                            <>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                    <DateField
                                        id="enrolmentDate"
                                        name="enrolmentDate"
                                        margin="normal"
                                        label={t('enrolment_date', 'Enrolment date')}
                                        fullWidth
                                        variant="outlined"
                                        value={formik.values.enrolmentDate}
                                        onChange={
                                            (value) => {
                                                formik.setFieldValue('enrolmentDate', value);
                                            }
                                        }
                                    />
                                </LocalizationProvider>
                            </>
                        )
                    }
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleAddDialogClose(null, null)} variant="outlined" color="secondary">{t('cancel', 'Cancel')}</Button>
                    <Button type="submit" variant="outlined">{t('save', 'Save')}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}