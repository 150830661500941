import { Alert, Box, Button, IconButton, InputAdornment, LinearProgress, Paper, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import LanguageSelector from 'components/selectors/LanguageSelector';
import { useAppSelector } from 'app/hooks';
import { useResetPasswordMutation } from 'features/api/apiSlice';
import { selectCurrentUser } from 'features/auth/authSlice';
import useProvideClient from 'hooks/client/useProvideClient';
import { Visibility as VisibilityOnIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';

export default function ResetPasswordPage() {
  const clientProvider = useProvideClient();
  const { t } = useTranslation();

  const [resetPassword] = useResetPasswordMutation();
  const navigate = useNavigate();
  const authUser = useAppSelector(selectCurrentUser);

  const [code, setCode] = useState("");
  const email = (new URLSearchParams(window.location.search)).get("email")!!
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false);

  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);

  if (authUser) {
    return <Navigate to="/patients" />; // redirect to profile page if already logged in
  }

  const handleCodeChange: (event: any) => void = (event) => {
    setCode(event.target.value);
  };

  const handleNewPasswordChange: (event: any) => void = (event) => {
    setNewPassword(event.target.value);
  };

  const handleRepeatNewPasswordChange: (event: any) => void = (event) => {
    setRepeatNewPassword(event.target.value);
  };

  const handleClickShowNewPassword: () => void = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleMouseDownNewPassword: (event: any) => void = (event) => {
    event.preventDefault();
  };

  const handleClickShowRepeatNewPassword: () => void = () => {
    setShowRepeatNewPassword(!showRepeatNewPassword);
  };

  const handleMouseDownRepeatNewPassword: (event: any) => void = (event) => {
    event.preventDefault();
  };

  const handleSubmit: (event: any) => void = async e => {
    e.preventDefault()

    setIsResettingPassword(true)
    await resetPassword({email, newPassword, code})
    .unwrap()
    .then((result) => {
      setIsResettingPassword(false)
      setShowError(false)
      navigate("/")
    })
    .catch((error) => {
      setIsResettingPassword(false)
      switch (error.status)
      {
        case 403:
          setError(t('invalid_code', 'Invalid code.')!!)
          break;
        case 500:
          setError(t('something_went_wrong', 'Something went wrong.')!!)
          break;
        default:
          setError(t('something_went_wrong', 'Something went wrong.')!!)
          break;
      }
      setShowError(true);
    })
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage: `url("data:image/svg+xml;base64,${btoa(clientProvider.client.backgroundLoginLeft)}")`,
          backgroundRepeat: 'repeat',
          backgroundSize: 'inherit',
          backgroundPosition: 'center'
        }}
      />
      <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
        <Stack sx={{ position: "fixed", top: '2%', right: '1%' }}>
          <LanguageSelector />
        </Stack>
        <Stack
          width='100%'
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box component="img" src={clientProvider.client.logo} maxWidth='30%' sx={{ my: 2 }} />

          {isResettingPassword ?
            <LinearProgress sx={{ width: "60%" }} />
            :
            <>
              <Typography component="h1" variant="h6" color="#b8bacc">
                {t("enter_reset_password_data", "Enter the code you received by email and the new password.")}
              </Typography>
              <Stack component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, height: '100%', width: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <TextField
                  variant='standard'
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label={t("code", "Code")}
                  name="code"
                  autoComplete="code"
                  autoFocus
                  value={code}
                  onChange={handleCodeChange}
                />

                <TextField
                  variant='standard'
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label={t("new_password", "New password")}
                  type={showNewPassword ? 'text' : 'password'}
                  id="newPassword"
                  autoComplete="current-password"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                        </IconButton>
                      </InputAdornment>
                  }}
                />

                <TextField
                  variant='standard'
                  margin="normal"
                  required
                  fullWidth
                  name="repeatNewPassword"
                  label={t("repeat_new_password", "Repeat new password")}
                  type={showRepeatNewPassword ? 'text' : 'password'}
                  id="repeatNewPassword"
                  autoComplete="current-password"
                  value={repeatNewPassword}
                  onChange={handleRepeatNewPasswordChange}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowRepeatNewPassword}
                          onMouseDown={handleMouseDownRepeatNewPassword}
                          edge="end"
                        >
                          {showRepeatNewPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                        </IconButton>
                      </InputAdornment>
                  }}
                />

                {showError &&
                  <Alert sx={{ width: '100%', my: 2 }} severity="error">
                    {error}
                  </Alert>
                }

                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                  disabled={!code || !newPassword || !repeatNewPassword || newPassword != repeatNewPassword}
                >
                  {t("save", "Save")}
                </Button>
              </Stack>
            </>
          }
        </Stack>
      </Grid>
    </Grid>
  );
}
