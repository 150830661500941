import { Alert, Snackbar, Typography, useTheme } from "@mui/material";
import { useMediaQuery, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { Stack } from "@mui/system";
import { EmptyTable, HeaderSort, TablePagination } from "components/third-party/ReactTable";
import { IAnnotation } from "models/IAnnotation";
import { IUser } from "models/IUser";
import moment from "moment";
import React, { useState } from "react";
import { useMemo, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { HeaderGroup, useTable, useGlobalFilter, useFilters, useSortBy, useExpanded, usePagination, useRowSelect, Row, Cell, Column } from "react-table";
import { renderFilterTypes, GlobalFilter } from "utils/react-table";

interface Props {
    annotations: IAnnotation[];
    users: IUser[],
    isLoading: boolean,
    isFetching: boolean
}

export function AnnotationsTable({ annotations, users, isLoading, isFetching }: Props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    const filterTypes = useMemo(() => renderFilterTypes, []);
    const sortBy = { id: 'creationDate', desc: true };

    const columns = useMemo(
        () => [
          {
            Header: t('id', 'Id'),
            accessor: 'id'
          },
          {
            Header: t('from', 'From'),
            accessor: 'creationUserId',
            Cell: ({ row }: { row: Row }) => {
                const creationUser = users?.find(x => x.id == row.values.creationUserId)
      
                return (
                  <Stack direction="row" spacing={1.5} alignItems="center">
                    <Stack spacing={0}>
                      <Typography>{creationUser?.firstName} {creationUser?.lastName}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {creationUser?.id}
                      </Typography>
                    </Stack>
                  </Stack>
                );
            }
          },
          {
            Header: t('creation_date', 'Creation date'),
            accessor: 'creationDate',
            Cell: ({ row }: { row: Row }) => {
                return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                      <Stack spacing={0}>
                        <Typography>{(moment(row.values.creationDate.toString()).locale('es').format('l LTS'))}</Typography>
                      </Stack>
                    </Stack>
                )
            }
          },
          {
            Header: t('message', 'Message'),
            accessor: 'text'
          }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [theme, /*patients,*/ annotations]
    );

    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string | null>();
    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setShowSnackbar(false);
      setSnackbarMessage(null)
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows,
      page,
      gotoPage,
      setPageSize,
      state: { globalFilter, pageIndex, pageSize },
      preGlobalFilteredRows,
      setGlobalFilter
    } = useTable(
      {
        columns: columns as Column[],
        data: annotations,
        autoResetPage: false,
        autoResetFilters: false,
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: ['id'], sortBy: [sortBy] }
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
      useExpanded,
      usePagination,
      useRowSelect
    );

    return (
      <>
        <Stack spacing={3}>
          <Stack
            direction={matchDownSM ? 'column' : 'row'}
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 3, pb: 0 }} >
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                size="small"
            />
          </Stack>
          <Table {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup: HeaderGroup<{}>) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: HeaderGroup) => (
                  <TableCell {...column.getHeaderProps([{ className: column.className }, column.getSortByToggleProps()])}>
                    <HeaderSort column={column} />
                  </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {
                isLoading ? (
                  <EmptyTable msg={t('loading', 'Loading...')} colSpan={7} />
                ) : isFetching ? (
                  <EmptyTable msg={t('fetching', 'Fetching...')} colSpan={7} />
                ) : page.length > 0 ? (
                  page.map((row: Row, i: number) => {
                    prepareRow(row);

                    return (
                      <Fragment key={i}>
                        <TableRow
                          {...row.getRowProps()}
                          sx={{ cursor: 'pointer', bgcolor: 'inherit' }} >
                            {row.cells.map((cell: Cell) => (
                              <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</TableCell>
                            ))}
                        </TableRow>
                        {row.isExpanded}
                      </Fragment>
                    );
                  })
                ) : (
                  <EmptyTable msg={t('no_data_found', 'Data not found')} colSpan={7} />
                )
              }
              <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
              <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
                <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
              </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>

        <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>{snackbarMessage}</Alert>
        </Snackbar>
      </>
    );
}
