import { useMemo, useState, Fragment } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Alert,
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';

// third-party
import {
  useFilters,
  useExpanded,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
  usePagination,
  Column,
  HeaderGroup,
  Row,
  Cell
} from 'react-table';

// project import
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import IconButton from 'components/@extended/IconButton';
import {
  EmptyTable,
  HeaderSort,
  TablePagination
} from 'components/third-party/ReactTable';

//import makeData from 'pages/admin/react-table';
import { renderFilterTypes, GlobalFilter } from 'utils/react-table';

// assets
import { PlusOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';

import { useTranslation } from "react-i18next";
import { useDeleteLiteralMutation, useGetLanguagesQuery, useGetLiteralsQuery, usePatchLiteralMutation, usePostLiteralMutation } from 'features/api/apiSlice';
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { ILiteral } from 'models/ILiteral';
import { TFunction } from "i18next";

import React from 'react';
import GenericDeleteDialog from 'components/dialogs/Generic/GenericDeleteDialog';
import AddLiteralDialog from 'components/dialogs/Languages/AddLiteralDialog';
import UpdateLiteralDialog from 'components/dialogs/Languages/UpdateLiteralDialog';

// ==============================|| REACT TABLE ||============================== //

interface Props {
  columns: Column[]
  literals: ILiteral[]
  handleOpenAddLiteralDialog: () => void
  getHeaderProps: (column: HeaderGroup) => {}
  t: TFunction<"translation", undefined, "translation">
  isLoading: boolean
  isFetching: boolean
}

function LiteralsTable({ columns, literals, handleOpenAddLiteralDialog, getHeaderProps, t, isLoading, isFetching }: Props) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const filterTypes = useMemo(() => renderFilterTypes, []);
  const sortBy = { id: 'fatherName', desc: false };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { globalFilter, pageIndex, pageSize },
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data: literals,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: ['id'], sortBy: [sortBy] }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  return (
    <Stack spacing={3}>
      <Stack
        direction={matchDownSM ? 'column' : 'row'}
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 3, pb: 0 }}
      >
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          size="small"
        />
        <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={1}>
          <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleOpenAddLiteralDialog} size="small" sx={{textTransform: 'none'}}>
            {t('add_literal', 'Add literal')}
          </Button>
        </Stack>
      </Stack>
      <Table {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup: HeaderGroup<{}>) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup) => (
                <TableCell {...column.getHeaderProps([{ className: column.className }, getHeaderProps(column)])}>
                  <HeaderSort column={column} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {
            isLoading ? (
              <EmptyTable msg={t('loading', 'Loading...')} colSpan={7} />
            ) : isFetching ? (
              <EmptyTable msg={t('fetching', 'Fetching...')} colSpan={7} />
            ) : page.length > 0 ? (
              page.map((row: Row, i: number) => {
                prepareRow(row);
    
                return (
                  <Fragment key={i}>
                    <TableRow
                      {...row.getRowProps()}
                      sx={{ cursor: 'pointer', bgcolor: 'inherit' }}
                    >
                      {row.cells.map((cell: Cell) => (
                        <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</TableCell>
                      ))}
                    </TableRow>
                    {row.isExpanded}
                  </Fragment>
                );
              })
            ) : (
              <EmptyTable msg={t('no_data_found', 'Data not found')} colSpan={7} />
            )
          }
          <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
            <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
              <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
}

// ==============================|| LITERALS - LIST ||============================== //

const LiteralsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [selectedLanguage, setSelectedLanguage] = useState<string>("en_GB");
  const { data: languages, isLoading: isLoadingLanguages, isFetching: isFetchingLanguages } = useGetLanguagesQuery();
  const { data: literals, isLoading: isLoadingLiterals, isFetching: isFetchingLiterals } = useGetLiteralsQuery(selectedLanguage ? { languageId: selectedLanguage } : skipToken);

  const [literal, setLiteral] = useState<any>(null);
  const [showAddLiteralDialog, setShowAddLiteralDialog] = useState<boolean>(false);
  const [showUpdateLiteralDialog, setShowUpdateLiteralDialog] = useState<boolean>(false);
  const [showDeleteLiteralDialog, setShowDeleteLiteralDialog] = useState<boolean>(false);

  const handleOpenAddLiteralDialog = () => {
    setShowAddLiteralDialog(true)
  };

  const handleCloseAddLiteralDialog = () => {
    setShowAddLiteralDialog(false);
  };

  const handleOpenUpdateLiteralDialog = (literal: ILiteral) => {
    setLiteral(literal)
    setShowUpdateLiteralDialog(true)
  };

  const handleCloseUpdateLiteralDialog = () => {
    setShowUpdateLiteralDialog(false);
    setLiteral(null);
  };

  const handleOpenDeleteLiteralDialog = (literal: ILiteral) => {
    setLiteral(literal)
    setShowDeleteLiteralDialog(true)
  };

  const handleCloseDeleteLiteralDialog = () => {
    setShowDeleteLiteralDialog(false);
    setLiteral(null);
  };

  const handleSelectedLanguageChange = (event: SelectChangeEvent) => {
    setSelectedLanguage(event.target.value);
  };

  const [postLiteral] = usePostLiteralMutation();
  const handleAddLiteral = async (key: string, value: string) => {
    await postLiteral({ postLiteral: {isoCode: selectedLanguage, key: key, value: value }})
      .unwrap()
      .then((payload) => {
        setShowAddLiteralDialog(false);
      })
      .catch((error) => {
        setSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowSnackbar(true)
      })
  };

  const [patchLiteral] = usePatchLiteralMutation();
  const handleUpdateLiteral = async (literalId: number, value: string) => {
    await patchLiteral({ literalId, patchLiteral: { fields: ["Value"], isoCode: null, key: null, value }})
      .unwrap()
      .then((payload) => {
        setShowUpdateLiteralDialog(false);
      })
      .catch((error) => {
        setSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowSnackbar(true)
      })
  };

  const [deleteLiteral] = useDeleteLiteralMutation();
  const handleDeleteLiteral = async (literalId: number) => {
    await deleteLiteral({ literalId: literalId })
      .unwrap()
      .then((payload) => {
        setShowDeleteLiteralDialog(false);
      })
      .catch((error) => {
        setSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowSnackbar(true)
      })
  };

  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>();

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
    setSnackbarMessage(null)
  };

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id'
      },
      {
        Header: t('key', 'Key'),
        accessor: 'key'
      },
      {
        Header: t('value', 'Value'),
        accessor: 'value'
      },
      {
        Header: t('actions', 'Actions'),
        className: 'cell-center',
        disableSortBy: true,
        Cell: ({ row }: { row: Row<{}> }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Tooltip title={t('edit', 'Edit')}>
                <IconButton
                  color="primary"
                  onClick={() => handleOpenUpdateLiteralDialog(row.original)}
                >
                  <EditTwoTone twoToneColor={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('delete', 'Delete')}>
                <IconButton
                  color="error"
                  onClick={() => handleOpenDeleteLiteralDialog(row.original)}
                >
                  <DeleteTwoTone twoToneColor={theme.palette.error.main} />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme, t]
  );
  
  return (
    <>
      <Select sx={{ width: '250px', mb: 2 }} value={selectedLanguage} onChange={handleSelectedLanguageChange}>
        {languages?.map((language) => {
          return (
            <MenuItem value={language.isoCode} key={language.isoCode} >
              <Stack direction="row" spacing={2}>
                <Box component='img' src={`/images/flags/${language.flagIconCode}.svg`} maxWidth={20} />
                <Typography>{language.name}</Typography>
              </Stack>
            </MenuItem>
          )
        })}
      </Select>

      <MainCard content={false}>
        <ScrollX>
          <LiteralsTable
            columns={columns}
            literals={literals === undefined ? [] : literals}
            handleOpenAddLiteralDialog={handleOpenAddLiteralDialog}
            getHeaderProps={(column: HeaderGroup) => column.getSortByToggleProps()}
            t={t}
            isLoading={isLoadingLanguages || isLoadingLiterals}
            isFetching={isFetchingLanguages || isFetchingLiterals}
          />
        </ScrollX>
        {showDeleteLiteralDialog &&
            <GenericDeleteDialog
                openDialog={showDeleteLiteralDialog}
                handleClose={handleCloseDeleteLiteralDialog}
                title= {t('do_you_want_to_delete_x', 'Do you want to delete {0}?').replace('{0}', literal?.key)}
                message={t('this_action_cannot_be_undone', 'This action cannot be undone.')}
                handleDelete={() => handleDeleteLiteral(literal?.id)}
            />
        }
        
        {showAddLiteralDialog && <AddLiteralDialog openDialog={showAddLiteralDialog} handleClose={handleCloseAddLiteralDialog} handleAddLiteral={handleAddLiteral}/>}
        
        {showUpdateLiteralDialog && literal && <UpdateLiteralDialog openDialog={showUpdateLiteralDialog} handleClose={handleCloseUpdateLiteralDialog} handleUpdateLiteral={handleUpdateLiteral} literal={literal} />}
      
        <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>{snackbarMessage}</Alert>
        </Snackbar>
      </MainCard>
    </>
  )
};

export default LiteralsPage;
