import { DeviceBrandId, DeviceBrandName, SensorTypeId, SensorTypeName } from "models/ISensor";

export const getDeviceBrandName: (deviceBrandId: string) => string = (deviceBrandId: string) => {
    switch (deviceBrandId) {
        case DeviceBrandId.WoePal:
            return DeviceBrandName.WoePal;
        case DeviceBrandId.WAQI:
            return DeviceBrandName.WAQI;
        case DeviceBrandId.Flow:
            return DeviceBrandName.Flow;
        case DeviceBrandId.Fitbit:
            return DeviceBrandName.Fitbit;
        case DeviceBrandId.CapMedic:
            return DeviceBrandName.CapMedic;
        case DeviceBrandId.SakuraTech:
            return DeviceBrandName.SakuraTech;
        case DeviceBrandId.inBiot:
            return DeviceBrandName.inBiot;
        case DeviceBrandId.AwarenessApp:
            return DeviceBrandName.AwarenessApp;
        case DeviceBrandId.DSS2:
            return DeviceBrandName.DSS2;
        case DeviceBrandId.CEMIC:
            return DeviceBrandName.CEMIC;
        case DeviceBrandId.PolitoAndSAT:
            return DeviceBrandName.PolitoAndSAT;
        case DeviceBrandId.Sykno:
            return DeviceBrandName.Sykno;
        case DeviceBrandId.Fibaro:
            return DeviceBrandName.Fibaro;
        case DeviceBrandId.AlertiT:
            return DeviceBrandName.AlertiT;
        case DeviceBrandId.Unknown:
            return DeviceBrandName.Unknown;
        case DeviceBrandId.Anura:
            return DeviceBrandName.Anura;
        default:
            return "Unknown";
    }
};

export const getSensorTypeName: (sensorTypeId: string) => string = (sensorTypeId: string) => {
    switch (sensorTypeId) {
        case SensorTypeId.HeartRate:
            return SensorTypeName.HeartRate;
        case SensorTypeId.BreathingRate:
            return SensorTypeName.BreathingRate;
        case SensorTypeId.HRV:
            return SensorTypeName.HRV;
        case SensorTypeId.SPO2:
            return SensorTypeName.SPO2;
        case SensorTypeId.PM1:
            return SensorTypeName.PM1;
        case SensorTypeId.PM10:
            return SensorTypeName.PM10;
        case SensorTypeId.PM25:
            return SensorTypeName.PM25;
        case SensorTypeId.NO2:
            return SensorTypeName.NO2;
        case SensorTypeId.VOC:
            return SensorTypeName.VOC;
        case SensorTypeId.Pressure:
            return SensorTypeName.Pressure;
        case SensorTypeId.Temperature:
            return SensorTypeName.Temperature;
        case SensorTypeId.Humidity:
            return SensorTypeName.Humidity;
        case SensorTypeId.CO:
            return SensorTypeName.CO;
        case SensorTypeId.O3:
            return SensorTypeName.O3;
        case SensorTypeId.SO2:
            return SensorTypeName.SO2;
        case SensorTypeId.PEF:
            return SensorTypeName.PEF;
        case SensorTypeId.FEV1:
            return SensorTypeName.FEV1;
        case SensorTypeId.PUFF:
            return SensorTypeName.PUFF;
        case SensorTypeId.AirQualityIndex:
            return SensorTypeName.AirQualityIndex;
        case SensorTypeId.BreathingWave:
            return SensorTypeName.BreathingWave;
        case SensorTypeId.CardiacWave:
            return SensorTypeName.CardiacWave;
        case SensorTypeId.HeartbeatWave:
            return SensorTypeName.HeartbeatWave;
        case SensorTypeId.MovementWave:
            return SensorTypeName.MovementWave;
        case SensorTypeId.CO2:
            return SensorTypeName.CO2;
        case SensorTypeId.Formaldehyde:
            return SensorTypeName.Formaldehyde;
        case SensorTypeId.PM4:
            return SensorTypeName.PM4;
        case SensorTypeId.PHQ9Score:
            return SensorTypeName.PHQ9Score;
        case SensorTypeId.GAD7Score:
            return SensorTypeName.GAD7Score;
        case SensorTypeId.AnxietyLevel:
            return SensorTypeName.AnxietyLevel;
        case SensorTypeId.DepressionLevel:
            return SensorTypeName.DepressionLevel;
        case SensorTypeId.FormaldehydeCounts:
            return SensorTypeName.FormaldehydeCounts;
        case SensorTypeId.FormaldehydeDiffCounts:
            return SensorTypeName.FormaldehydeDiffCounts;
        case SensorTypeId.CO2Counts:
            return SensorTypeName.CO2Counts;
        case SensorTypeId.Type:
            return SensorTypeName.Type;
        case SensorTypeId.DrowsinessState:
            return SensorTypeName.DrowsinessState;
        case SensorTypeId.Latitude:
            return SensorTypeName.Latitude;
        case SensorTypeId.Longitude:
            return SensorTypeName.Longitude;
        case SensorTypeId.Altitude:
            return SensorTypeName.Altitude;
        case SensorTypeId.Door:
            return SensorTypeName.Door;
        case SensorTypeId.SmartPlug:
            return SensorTypeName.SmartPlug;
        case SensorTypeId.PressureMat:
            return SensorTypeName.PressureMat;
        case SensorTypeId.AmpereMeter:
            return SensorTypeName.AmpereMeter;
        case SensorTypeId.Distance:
            return SensorTypeName.Distance;
        case SensorTypeId.DistanceBreath:
            return SensorTypeName.DistanceBreath;
        case SensorTypeId.DistancePulse:
            return SensorTypeName.DistancePulse;
        case SensorTypeId.DistanceHeartSound:
            return SensorTypeName.DistanceHeartSound;
        default:
            return "Unknown";
    }
};