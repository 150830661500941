import { IClientContextProps, IClient } from "./ClientContext";
import { appsettings } from "../../app.config";
import { useEffect, useState } from "react";

declare global {
  interface Window { appclient: any; }
}

const useProvideClient: () => IClientContextProps = () => {
  const getDefaultClient: () => IClient = () => {
      const defaultClient = appsettings.clients["default"];
      return appsettings.clients[defaultClient];
  };
  const [client, setClient] = useState(getDefaultClient());

  useEffect(() => {
    // tslint:disable-next-line:forin

    let link: HTMLLinkElement | null = document.querySelector('link[rel="shortcut icon"]') || document.querySelector('link[rel="icon"]');
    if (!link) {
        link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        document.head.appendChild(link);
    }

    for (const key in appsettings.clients) {
      var item: IClient = appsettings.clients[key] as IClient;
      if (key === window.location.hostname) {
        link.href = `/${item.favicon}`;
        document.title = item.title;
        window.top!!.document.title = item.title;
        window.sessionStorage.setItem("appclient", JSON.stringify(item));
        setClient(item);
        break;
      }
    }
    
    if (window.location.hostname === "localhost") {
      var defaultClientName = appsettings.clients["default"];
      var defaultClient = appsettings.clients[defaultClientName];
      link.href = `/${defaultClient.favicon}`;
      document.title = defaultClient.title;
      window.top!!.document.title = defaultClient.title;
      window.sessionStorage.setItem("appclient", JSON.stringify(defaultClient));
      setClient(defaultClient);
    }
  }, [window.location]);

  return { client };
};

export default useProvideClient;
