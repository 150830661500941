import { Alert, Button, Checkbox, Snackbar, Typography, useTheme } from "@mui/material";
import { useMediaQuery, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { Stack } from "@mui/system";
import { EmptyTable, HeaderSort, TablePagination } from "components/third-party/ReactTable";
import { useMarkAllNotificationsAsReadByClinicianMutation, useMarkAllUserNotificationsAsReadByClinicianMutation, usePatchNotificationMutation } from "features/api/apiSlice";
import { INotification } from "models/INotification";
import { IUser } from "models/IUser";
import moment from "moment";
import React, { useState } from "react";
import { useMemo, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { HeaderGroup, useTable, useGlobalFilter, useFilters, useSortBy, useExpanded, usePagination, useRowSelect, Row, Cell, Column } from "react-table";
import { renderFilterTypes, GlobalFilter } from "utils/react-table";

interface Props {
    notifications: INotification[];
    patients: IUser[],
    isSingleUser: boolean,
    isLoading: boolean,
    isFetching: boolean
}

export function NotificationsTable({ notifications, patients, isSingleUser, isLoading, isFetching }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const filterTypes = useMemo(() => renderFilterTypes, []);
  const sortBy = { id: 'creationDate', desc: true };

  const [markNotificationsAsReadByClinician] = useMarkAllNotificationsAsReadByClinicianMutation();
  const handleMarkNotificationsAsReadByClinician = async () => {
    await markNotificationsAsReadByClinician()
      .unwrap()
      .catch((error) => {
        setSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowSnackbar(true)
      })
  };

  const [markAllUserNotificationsAsReadByClinician] = useMarkAllUserNotificationsAsReadByClinicianMutation();
  const handleMarkAllUserNotificationsAsReadByClinician = async () => {
    await markAllUserNotificationsAsReadByClinician(patients[0].id!!)
      .unwrap()
      .catch((error) => {
        setSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowSnackbar(true)
      })
  };

  const [updateNotification] = usePatchNotificationMutation();
  const handleUpdateNotification = async (notificationId: string, readByClinician: boolean) => {
    await updateNotification({notificationId, patchNotification: {fields: ["ReadByClinician"], readByClinician}})
      .unwrap()
      .catch((error) => {
        setSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowSnackbar(true)
      })
  };

  const columns = useMemo(
    () => [
      {
        Header: t('id', 'Id'),
        accessor: 'id'
      },
      {
        Header: t('destination_user', 'Destination user'),
        accessor: 'destinationUserId',
        Cell: ({ row }: { row: Row }) => {
            const destinationUser = patients?.find(x => x.id == row.values.destinationUserId)
  
            return (
              <Stack direction="row" spacing={1.5} alignItems="center">
                <Stack spacing={0}>
                  <Typography>{destinationUser?.firstName} {destinationUser?.lastName}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {destinationUser?.id}
                  </Typography>
                </Stack>
              </Stack>
            );
        }
      },
      {
        Header: t('creation_date', 'Creation date'),
        accessor: 'creationDate',
        Cell: ({ row }: { row: Row }) => {
            return (
                <Stack direction="row" spacing={1.5} alignItems="center">
                  <Stack spacing={0}>
                    <Typography>{(moment(row.values.creationDate.toString()).locale('es').format('l LTS'))}</Typography>
                  </Stack>
                </Stack>
            )
        }
      },
      {
        Header: t('type', 'Type'),
        accessor: 'type'
      },
      {
        Header: t('action', 'Action'),
        accessor: 'action'
      },
      {
        Header: t('silent', 'Silent'),
        accessor: 'silent'
      },
      {
        Header: t('message', 'Message'),
        accessor: 'stringId',
        Cell: ({ row }: { row: Row }) => {
            return (
                <Stack direction="row" spacing={1.5} alignItems="center">
                  <Stack spacing={0}>
                    <Typography>{t(row.values.stringId, 'notification.stringId').replace('{0}', row.values.args[0]).replace('{1}', moment(row.values.args[1]).locale('es').format('l LTS'))}</Typography>
                  </Stack>
                </Stack>
            )
        }
      },
      {
        Header: t('arguments', 'Arguments'),
        accessor: 'args'
      },
      {
        Header: t('read_by_clinician', 'Read by clinician'),
        accessor: 'readByClinician',
        Cell: ({ row }: { row: Row }) => {
          if(row.values.id == 36691) {
            return (
                <Stack direction="row" spacing={1.5} alignItems="center">
                  <Stack spacing={0}>
                    <Checkbox defaultChecked={row.values.readByClinician} onChange={e => handleUpdateNotification(row.values.id, e.target.checked)}/>
                  </Stack>
                </Stack>
            )
          } else {
            return (
                <Stack direction="row" spacing={1.5} alignItems="center">
                  <Stack spacing={0}>
                    <Checkbox defaultChecked={row.values.readByClinician} onChange={e => handleUpdateNotification(row.values.id, e.target.checked)}/>
                  </Stack>
                </Stack>
            )
          }
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme, /*patients,*/ notifications, t]
  );

  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>();
  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
    setSnackbarMessage(null)
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { globalFilter, pageIndex, pageSize },
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
      columns: columns as Column[],
      data: notifications,
      autoResetPage: false,
      autoResetFilters: false,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: ['id', 'silent', 'action', 'args', 'type', isSingleUser ? 'destinationUserId' : ''], sortBy: [sortBy] }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  return (
    <>
      <Stack spacing={3}>
        <Stack
          direction={matchDownSM ? 'column' : 'row'}
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 3, pb: 0 }} >
          <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              size="small"
          />
          <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={1}>
            <Button variant="contained" size="small" onClick={isSingleUser ? handleMarkAllUserNotificationsAsReadByClinician : handleMarkNotificationsAsReadByClinician} sx={{textTransform: 'none'}}>
              {t('mark_all_as_read', 'Mark all as read')}
            </Button>
          </Stack>
        </Stack>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup: HeaderGroup<{}>) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: HeaderGroup) => (
                <TableCell {...column.getHeaderProps([{ className: column.className }, column.getSortByToggleProps()])}>
                  <HeaderSort column={column} />
                </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {
              isLoading ? (
                <EmptyTable msg={t('loading', 'Loading...')} colSpan={7} />
              ) : isFetching ? (
                <EmptyTable msg={t('fetching', 'Fetching...')} colSpan={7} />
              ) : page.length > 0 ? (
                page.map((row: Row, i: number) => {
                  prepareRow(row);
    
                  return (
                    <Fragment key={i}>
                      <TableRow
                        {...row.getRowProps()}
                        sx={{ cursor: 'pointer', bgcolor: 'inherit' }} >
                          {row.cells.map((cell: Cell) => (
                            <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</TableCell>
                          ))}
                      </TableRow>
                      {row.isExpanded}
                    </Fragment>
                  );
                })
              ) : (
                <EmptyTable msg={t('no_data_found', 'Data not found')} colSpan={7} />
              )
            }
            <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
            <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
              <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
            </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>

      <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>{snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
}
