import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { Mutex } from 'async-mutex'
import { appsettings, authHeaderName, baseUrl } from 'app.config'
import { RootState } from 'app/store'
import { IRefreshTokenRequest } from 'models/api/requests/authentication/IRefreshTokenRequest'
import { IRefreshTokenResponse } from 'models/api/responses/authentication/IRefreshTokenResponse'
import { localLogout, tokenReceived } from '../auth/authSlice'
import { apiSlice } from './apiSlice'
import { IClient } from 'hooks/client/ClientContext'

const mutex = new Mutex()
const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.accessToken;

        if (token) {
            headers.set(authHeaderName, `Bearer ${token}`);
        }

        var client = appsettings.clients[window.location.hostname] as IClient
        if (!client){
            var defaultClientName = appsettings.clients["default"];
            client = appsettings.clients[defaultClientName] as IClient;
        }
        headers.set("Provider", client.provider)

        return headers;
    }
})

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    await mutex.waitForUnlock()

    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire()

            try {
                const userId = (api.getState() as RootState).auth.user?.id;
                const refreshToken = (api.getState() as RootState).auth.refreshToken;
                const refreshResult = await baseQuery(
                    {url: '/auth/refreshToken', method: "POST", body: {userId, refreshToken} as IRefreshTokenRequest},
                    api,
                    extraOptions
                )

                const tokensResponse = refreshResult.data as IRefreshTokenResponse;
                if (tokensResponse) {
                    api.dispatch(tokenReceived(tokensResponse.data));
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions)
                } else {
                    api.dispatch(localLogout());
                    api.dispatch(apiSlice.util.resetApiState());
                }
            } finally {
                release()
            }
        } else {
            await mutex.waitForUnlock()
            result = await baseQuery(args, api, extraOptions)
        }
    }

    return result
}