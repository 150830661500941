import { useState, SyntheticEvent } from 'react';

// material-ui
import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Snackbar,
    Stack,
    TextField,
    Typography
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';

import { isNumber, isLowercaseChar, isUppercaseChar, isSpecialChar, minLength } from 'utils/password-validation';

// third party
import * as yup from 'yup';
import { useFormik } from 'formik';

// assets
import { CheckOutlined, LineOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useChangePasswordMutation } from 'features/api/apiSlice';
import React from 'react';

// ==============================|| TAB - PASSWORD CHANGE ||============================== //

const TabChangePassword = () => {
    const { t } = useTranslation()

    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false)

    const [changePassword, changePasswordResult] = useChangePasswordMutation()

    const handleClickShowOldPassword = () => {
        setShowOldPassword(!showOldPassword)
    }
    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword)
    }
    const handleClickShowRepeatNewPassword = () => {
        setShowRepeatNewPassword(!showRepeatNewPassword);
    }
    const handleMouseDownPassword = (event: SyntheticEvent) => {
        event.preventDefault()
    }

    const validationSchema = yup.object({
        oldPassword: yup.string().required(t('field_required', 'This field is required.')!!),
        newPassword: yup.string().required(t('field_required', 'This field is required.')!!)
            .matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                t('password_requirements', 'Password must contain at least 8 characters, one uppercase, one number and one special case character.')!!
            ),
        repeatNewPassword: yup.string().required(t('field_required', 'This field is required.')!!)
            .test('confirm', t('passwords_dont_match', 'Passwords don\'t match.')!!, (confirm: string, yup: any) => yup.parent.newPassword === confirm)
    })

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            repeatNewPassword: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            await changePassword({ oldPassword: values.oldPassword, newPassword: values.newPassword })
            .unwrap()
            .then((payload) => {
                
            })
            .catch((error) => {
                switch (error.status) {
                    case 403:
                        setSnackbarMessage(t('invalid_old_password', 'Invalid old password.')!!)
                        break
                    default:
                        setSnackbarMessage(t('something_went_wrong', 'Something went wrong.')!!)
                        break
                }
                setShowSnackbar(true)
            })
        }
    })

    const [showSnackbar, setShowSnackbar] = React.useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string | null>()

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }

        setShowSnackbar(false)
        setSnackbarMessage(null)
    }

    return (
        <MainCard>
            <form noValidate onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item container spacing={3} xs={12} sm={6}>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                            <TextField
                                id="oldPassword"
                                name="oldPassword"
                                margin="normal"
                                label={t('old_password', 'Old password')}
                                type={showOldPassword ? "text" : "password"}
                                fullWidth
                                variant="outlined"
                                required
                                value={formik.values.oldPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                                helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowOldPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                color='secondary'
                                            >
                                                {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                            <TextField
                                id="newPassword"
                                name="newPassword"
                                margin="normal"
                                label={t('new_password', 'New password')}
                                type={showNewPassword ? "text" : "password"}
                                fullWidth
                                variant="outlined"
                                required
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                                helperText={formik.touched.newPassword && formik.errors.newPassword}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                color='secondary'
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                            <TextField
                                id="repeatNewPassword"
                                name="repeatNewPassword"
                                margin="normal"
                                label={t('repeat_new_password', 'Repeat new password')}
                                type={showRepeatNewPassword ? "text" : "password"}
                                fullWidth
                                variant="outlined"
                                required
                                value={formik.values.repeatNewPassword}
                                onChange={formik.handleChange}
                                error={formik.touched.repeatNewPassword && Boolean(formik.errors.repeatNewPassword)}
                                helperText={formik.touched.repeatNewPassword && formik.errors.repeatNewPassword}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowRepeatNewPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                color='secondary'
                                            >
                                                {showRepeatNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Box sx={{ p: { xs: 0, sm: 2, md: 4, lg: 5 } }}>
                        <Typography variant="h5">{t('new_password_must_contain', 'New password must contain:')}</Typography>
                        <List sx={{ p: 0, mt: 1 }}>
                        <ListItem divider>
                            <ListItemIcon sx={{ color: minLength(formik.values.newPassword) ? 'success.main' : 'inherit' }}>
                            {minLength(formik.values.newPassword) ? <CheckOutlined /> : <LineOutlined />}
                            </ListItemIcon>
                            <ListItemText primary={t('at_least_8_characters', 'At least 8 characters')} />
                        </ListItem>
                        <ListItem divider>
                            <ListItemIcon sx={{ color: isLowercaseChar(formik.values.newPassword) ? 'success.main' : 'inherit' }}>
                            {isLowercaseChar(formik.values.newPassword) ? <CheckOutlined /> : <LineOutlined />}
                            </ListItemIcon>
                            <ListItemText primary={t('at_least_1_lower_letter', 'At least 1 lower letter (a-z)')} />
                        </ListItem>
                        <ListItem divider>
                            <ListItemIcon sx={{ color: isUppercaseChar(formik.values.newPassword) ? 'success.main' : 'inherit' }}>
                            {isUppercaseChar(formik.values.newPassword) ? <CheckOutlined /> : <LineOutlined />}
                            </ListItemIcon>
                            <ListItemText primary={t('at_least_1_uppercase_letter', 'At least 1 uppercase letter (A-Z)')} />
                        </ListItem>
                        <ListItem divider>
                            <ListItemIcon sx={{ color: isNumber(formik.values.newPassword) ? 'success.main' : 'inherit' }}>
                            {isNumber(formik.values.newPassword) ? <CheckOutlined /> : <LineOutlined />}
                            </ListItemIcon>
                            <ListItemText primary={t('at_least_1_number', 'At least 1 number (0-9)')} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon sx={{ color: isSpecialChar(formik.values.newPassword) ? 'success.main' : 'inherit' }}>
                            {isSpecialChar(formik.values.newPassword) ? <CheckOutlined /> : <LineOutlined />}
                            </ListItemIcon>
                            <ListItemText primary={t('at_least_1_special_character', 'At least 1 special character')} />
                        </ListItem>
                        </List>
                    </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                        <Button type="submit" variant="contained">{t('save', 'Save')}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </form>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={changePasswordResult.isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
                <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>{snackbarMessage}</Alert>
            </Snackbar>
        </MainCard>
    )
}

export default TabChangePassword;