export const getAnxietyLevel: (anxietyLevel: number) => string = (anxietyLevel: number) => {
    switch (anxietyLevel) {
        case 0:
            return "minimal";
        case 1:
            return "mild";
        case 2:
            return "moderate";
        case 3:
            return "severe";
        default:
            return "Unknown";
    }
};

export const getDepressionLevel: (depressionLevel: number) => string = (depressionLevel: number) => {
    switch (depressionLevel) {
        case 0:
            return "none/minimal";
        case 1:
            return "mild";
        case 2:
            return "moderate";
        case 3:
            return "moderately severe";
        case 4:
            return "severe";
        case 5:
            return "risk of suicide ideation";
        default:
            return "Unknown";
    }
};