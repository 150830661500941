import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import Transitions from 'components/@extended/Transitions';

// assets
import { BellOutlined, CheckCircleOutlined, FileDoneOutlined } from '@ant-design/icons';

// types
import { ThemeMode } from 'types/config';
import { useGetNotificationsSummaryQuery, useGetUsersQuery, useMarkNotificationsAsReadByClinicianMutation, usePatchNotificationMutation } from 'features/api/apiSlice';
import moment from 'moment';
import { UserRole } from 'models/IUser';
import { useAppSelector } from 'app/hooks';
import { selectCurrentUser } from 'features/auth/authSlice';
import { INotification } from 'models/INotification';
import { useNavigate } from 'react-router';

import 'moment/locale/es';
import { useTranslation } from 'react-i18next';

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const anchorRef = useRef<any>(null);
  const [read, setRead] = useState(0);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = theme.palette.mode === ThemeMode.DARK ? 'grey.200' : 'grey.300';
  const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100';

  const { data: notificationsSummary } = useGetNotificationsSummaryQuery()
  const authUser = useAppSelector(selectCurrentUser);
  const { data: users/*, isLoading, isFetching*/ } = useGetUsersQuery({ parentId: authUser!!.id!!, role: UserRole.Patient })

  const userFirstNameAndLastName = (notification: INotification) => {
    const user = users!!.find(x => x.id == notification.destinationUserId)!!
    return user.firstName!! + " " + user.lastName!!
  }

  useEffect(() => {
    if (notificationsSummary)
      setRead(notificationsSummary.notReadByClinicianCount)
  }, [notificationsSummary]);

  const navigate = useNavigate();
  const handleNavigateToNotifications = () => {
    navigate("/notifications");
    setOpen(false)
  };
  
  const [updateNotification] = usePatchNotificationMutation();
  const handleUpdateNotification = async (notificationId: string, readByClinician: boolean) => {
    await updateNotification({notificationId, patchNotification: {fields: ["ReadByClinician"], readByClinician}})
      .unwrap()
      .catch((error) => {
        
      })
  };

  const [markNotificationsAsReadByClinicianMutation] = useMarkNotificationsAsReadByClinicianMutation();
  const handleMarkNotificationsAsReadByClinicianMutation = async (ids: number[]) => {
    await markNotificationsAsReadByClinicianMutation({markNotificationsAsReadByClinician: {ids}})
      .unwrap()
      .catch((error) => {
        
      })
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={read} color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? -5 : 0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position={matchesXs ? 'top' : 'top-right'} sx={{ overflow: 'hidden' }} in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: '100%',
                minWidth: 400,
                maxWidth: 600,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 400
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title={t('notifications', 'Notifications')}
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <>
                      {notificationsSummary!!.notReadByClinicianCount > 0 && (
                        <Tooltip title={t('mark_all_of_these_as_read', 'Mark all of these as read')}>
                          <IconButton color="success" size="small" onClick={() => handleMarkNotificationsAsReadByClinicianMutation(notificationsSummary!!.lastTenNotificationsNotReadByClinician.map(x => x.id))/*setRead(0)*/}>
                            <FileDoneOutlined style={{ fontSize: '1.5rem' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                  <List
                    component="nav"
                    sx={{
                      p: 0,
                      '& .MuiListItemButton-root': {
                        py: 0.5,
                        '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                        '& .MuiAvatar-root': avatarSX,
                        '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                      }
                    }}
                  >   
                    {notificationsSummary?.lastTenNotificationsNotReadByClinician?.length == 0
                      ? <>
                        <ListItemButton sx={{ textAlign: 'center', py: `${12}px !important` }}>
                          <ListItemText
                            primary={
                              <Typography variant="h6">
                                {t('no_notifications_pending_read', 'There are no notifications pending to read.')}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <Divider /></>
                      : notificationsSummary?.lastTenNotificationsNotReadByClinician?.slice(0, 10).map((notification, i) => (
                        <><ListItemButton sx={{ py: `${0}px !important` }}>
                          <ListItemText
                            primary={
                              <Typography variant="caption" color="textSecondary">
                                <span>{userFirstNameAndLastName(notification)}</span>
                                <span style={{ float: 'right' }}>{moment(notification.creationDate.toString()).locale('es').format('l LTS')}</span>
                              </Typography>
                            }
                            secondary={<Typography variant="h6">{t(notification.stringId, 'notification.stringId').replace('{0}', notification.args[0]).replace('{1}', moment(notification.args[1]).locale('es').format('l LTS'))}</Typography>}/>
                          <Tooltip title={t('mark_as_read', 'Mark as read')}>
                            <IconButton color="success" size="small" style={{ marginLeft: '10px'}} onClick={e => handleUpdateNotification(notification.id.toString(), true)}>
                              <CheckCircleOutlined style={{ fontSize: '1.15rem' }} />
                            </IconButton>
                          </Tooltip>
                        </ListItemButton>
                        <Divider /></>
                    ))}
                    <ListItemButton sx={{ textAlign: 'center', py: `${12}px !important` }} onClick={handleNavigateToNotifications}>
                      <ListItemText
                        primary={
                          <Typography variant="h6" color="primary">
                            {t('view_all', 'View all')}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
