import { Divider, Grid, useTheme } from "@mui/material";
import HumanBodyDigitalTwin from 'assets/images/human_body_digital_twin.svg';
import SensorValueCard from "../../../components/cards/SensorValueCard";
import { DeviceBrandId, ISensorDataExtended } from "models/ISensor";
import { IDeviceDataExtended } from "models/IUser";
import { useTranslation } from "react-i18next";

const PatientHealthData = ({ devicesDataExtended }: { devicesDataExtended: IDeviceDataExtended[] }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Grid
            width="100%"
            sx={{
                /*backgroundColor: 'black',*/
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
            paddingTop={3}
        >
            <div
                style={{
                    overflowX: 'auto',
                    display: 'block'
                }}
            >
                <Grid
                    width={950}
                    height={680.867}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                    sx={{
                        backgroundImage: 'url('+HumanBodyDigitalTwin+')',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'inherit',
                        backgroundPosition: 'center',
                        backgroundColor: 'white'
                    }}
                >
                    <Grid
                        width={200}
                        position="absolute"
                        top="15px"
                        left="2px"
                        border="1px solid"
                        borderRadius={1}
                        borderColor={theme.palette.divider}
                        direction="column"
                        gap={1}
                        container
                        padding="10px"
                    >
                        <Grid item textAlign="center" fontWeight="bold">{t('physiological_data', 'Physiological data')}</Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item container spacing={1}>
                            {
                                devicesDataExtended!!.find(x => x.brandId == DeviceBrandId.Fitbit || x.brandId == DeviceBrandId.SakuraTech || x.brandId == DeviceBrandId.inBiot)?.sensorsDataExtended.map((sensorDataExtended: ISensorDataExtended, index: number) => (
                                    <Grid item xs={12}>
                                        <SensorValueCard sensorDataExtended={sensorDataExtended} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>

                    <Grid
                        width={200}
                        position="absolute"
                        top="15px"
                        left="750px"
                        border="1px solid"
                        borderRadius={1}
                        borderColor={theme.palette.divider}
                        direction="column"
                        gap={1}
                        container
                        padding="10px"
                    >
                        <Grid item textAlign="center" fontWeight="bold">{t('air_quality', 'Air quality')}</Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid container spacing={1}>
                            {
                                devicesDataExtended!!.find(x => x.brandId == DeviceBrandId.Flow)?.sensorsDataExtended.map((sensorDataExtended: ISensorDataExtended, index: number) => (
                                    <Grid item xs={12}>
                                        <SensorValueCard sensorDataExtended={sensorDataExtended} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
}

export default PatientHealthData;