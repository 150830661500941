import {
    Navigate,
} from 'react-router-dom';
import MainLayout from '../layout/MainLayout';
import { useAppSelector } from '../app/hooks';
import { selectCurrentUser } from '../features/auth/authSlice';

export type PrivateRouteProps = {
    authenticationPath: string;
    outlet: JSX.Element;
};

export default function PrivateRoute({ authenticationPath, outlet }: PrivateRouteProps) {
    const authUser = useAppSelector(selectCurrentUser);

    if (authUser) {
        return (
            <MainLayout>
                {outlet}
            </MainLayout>
        )
    } else {
        return <Navigate to={{ pathname: authenticationPath }} />;
    }
};