import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControl, Autocomplete, Box, Stack, Typography} from "@mui/material";
import { useState } from "react";
import { getUserProviderName, getUserRoleName } from "helpers/UserExtensions";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { IUser, UserRole } from "models/IUser";
import { useTranslation } from "react-i18next";

export type IUpdateUserDialogProps = {
    openDialog: boolean;
    user: IUser;
    users: IUser[];
    handleClose: () => void;
    handleUpdateUser: (userId: string, firstName: string, lastName: string, email: string, culture: string | null, parentId: string, enrolmentDate: string | null, meta: string | null) => void;
};

export default function UpdateUserDialog({ openDialog, user, users, handleClose, handleUpdateUser }: IUpdateUserDialogProps) {
    const { t } = useTranslation();

    const [filteredUsers] = useState(users!!.filter(x => x.role!! < user.role!! && x.provider == user.provider));

    const handleUpdateDialogClose: (event: any, reason: any) => void = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        handleClose();
    };

    const validationSchema = yup.object({
        firstName: yup.string().required(t('field_required', 'This field is required.')!!),
        lastName: yup.string().required(t('field_required', 'This field is required.')!!),
        email: yup.string().email(t('wrong_email_format', 'Wrong email format.')!!).required(t('field_required', 'This field is required.')!!),
        parent: yup.object().required(t('field_required', 'This field is required.')!!),
        role: yup.string().required(t('field_required', 'This field is required.')!!)
    });

    const formik = useFormik({
        initialValues: {
            firstName: user.firstName!!,
            lastName: user.lastName!!,
            email: user.email!!,
            provider: getUserProviderName(user.provider!!),
            parent: filteredUsers.find(x => x.id == user.parentId),
            role: getUserRoleName(user.role),
            enrolmentDate: user.enrolmentDate == null ? null as dayjs.Dayjs | null : dayjs(user.enrolmentDate)
        },
        validationSchema,
        onSubmit: (values) => {
            handleUpdateUser(
                user.id!!,
                values.firstName,
                values.lastName,
                values.email,
                user.culture ?? null,
                values.parent!!.id!!,
                values.enrolmentDate == null ? null : moment.utc(values.enrolmentDate.toString()).format('YYYY-MM-DDTHH:mm:ss Z'),
                user.meta ?? null
            )
        }
    });

    return (
        <Dialog
            open={openDialog}
            onClose={handleUpdateDialogClose}
        >
            <form noValidate onSubmit={formik.handleSubmit}>
                <DialogTitle>{t('edit_user', 'Edit user')}</DialogTitle>
                <DialogContent>
                    <TextField
                        id="firstName"
                        name="firstName"
                        margin="normal"
                        label={t('first_name', 'First name')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                    />

                    <TextField
                        id="lastName"
                        name="lastName"
                        margin="normal"
                        label={t('last_name', 'Last name')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                    />
                    
                    <TextField
                        id="email"
                        name="email"
                        margin="normal"
                        label={t('email', 'Email')}
                        type="email"
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />

                    <TextField
                        id="provider"
                        name="provider"
                        margin="normal"
                        label={t('provider', 'Provider')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.provider}
                        onChange={formik.handleChange}
                        error={formik.touched.provider && Boolean(formik.errors.provider)}
                        helperText={formik.touched.provider && formik.errors.provider}
                        disabled
                    />
                    
                    <FormControl margin="normal" fullWidth>
                        <Autocomplete
                            fullWidth
                            id="parent-autocomplete-label"
                            options={filteredUsers!!}
                            value={formik.values.parent}
                            onChange={(event, parent) => formik.setFieldValue('parent', parent)}
                            getOptionLabel={(parent) =>`${parent.firstName} ${parent.lastName}`}
                            renderInput={(params) => 
                                <TextField
                                    {...params}
                                    name="parent"
                                    error={formik.touched.parent && Boolean(formik.errors.parent)}
                                    helperText={(formik.touched.parent && formik.errors.parent) ? <>{formik.errors.parent}</> : null}
                                    label={t('parent', 'Parent')}
                                    required
                                />
                            }
                            renderOption={(props, parent) => (
                                <Box component="li" {...props}>
                                    <Stack direction="row" spacing={1.5} alignItems="center">
                                        <Stack spacing={0}>
                                            <Typography>{parent?.firstName} {parent?.lastName}</Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                {parent?.id}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                            )}
                        />
                    </FormControl>

                    <TextField
                        id="role"
                        name="role"
                        margin="normal"
                        label={t('role', 'Role')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.role}
                        onChange={formik.handleChange}
                        error={formik.touched.role && Boolean(formik.errors.role)}
                        helperText={formik.touched.role && formik.errors.role}
                        disabled
                    />
                    
                    {
                        formik.values.role === getUserRoleName(UserRole.Patient) && (
                            <> 
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                    <DateField
                                        id="enrolmentDate"
                                        name="enrolmentDate"
                                        margin="normal"
                                        label={t('enrolment_date', 'Enrolment date')}
                                        fullWidth
                                        variant="outlined"
                                        value={formik.values.enrolmentDate}
                                        onChange={
                                            (value) => {
                                                formik.setFieldValue('enrolmentDate', value);
                                            }
                                        }
                                    />
                                </LocalizationProvider>
                            </>
                        )
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleUpdateDialogClose(null, null)} variant="outlined" color="secondary">{t('cancel', 'Cancel')}</Button>
                    <Button type="submit" variant="outlined">{t('save', 'Save')}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}