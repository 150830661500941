import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Fragment, useMemo, useState } from "react";
import { Alert, IconButton, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Cell, Column, HeaderGroup, Row, useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { TFunction } from "i18next";
import { GlobalFilter, renderFilterTypes } from "utils/react-table";
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import { EmptyTable, HeaderSort, TablePagination } from "components/third-party/ReactTable";
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { IOrganization } from "models/IOrganization";
import AddOrganizationDialog from "components/dialogs/Organizations/AddOrganizationDialog";
import UpdateOrganizationDialog from "components/dialogs/Organizations/UpdateOrganizationDialog";
import React from "react";
import { UserRole } from "models/IUser";
import GenericDeleteDialog from "components/dialogs/Generic/GenericDeleteDialog";
import { useAppSelector } from "app/hooks";
import { useGetOrganizationsQuery, useGetUsersQuery, usePostOrganizationMutation, usePutOrganizationMutation, useDeleteOrganizationMutation } from "features/api/apiSlice";
import { selectCurrentUser } from "features/auth/authSlice";
import { useTranslation } from "react-i18next";

// ==============================|| ORGANIZATIONS PAGE ||============================== //

export default function OrganizationsPage() {
  const { t } = useTranslation();
  const theme = useTheme();

  const authUser = useAppSelector(selectCurrentUser);

  const { data: organizations, isLoading: isLoadingOrganizations, isFetching: isFetchingOrganizations  } = useGetOrganizationsQuery({})
  const { data: users, isLoading: isLoadingUsers, isFetching: isFetchingUsers } = useGetUsersQuery({ parentId: authUser!!.id!!, role: UserRole.Manager })
  const allUsers = useMemo(() => users?.concat(authUser!!), [users, authUser])

  const [organization, setOrganization] = useState<any>(null);
  const [showAddOrganizationDialog, setShowAddOrganizationDialog] = useState<boolean>(false);
  const [showUpdateOrganizationDialog, setShowUpdateOrganizationDialog] = useState<boolean>(false);
  const [showDeleteOrganizationDialog, setShowDeleteOrganizationDialog] = useState<boolean>(false);

  const handleOpenAddOrganizationDialog = () => {
    setShowAddOrganizationDialog(true)
  };

  const handleCloseAddOrganizationDialog = () => {
    setShowAddOrganizationDialog(false);
  };

  const handleOpenUpdateOrganizationDialog = (organization: IOrganization) => {
    setOrganization(organization)
    setShowUpdateOrganizationDialog(true)
  };

  const handleCloseUpdateOrganizationDialog = () => {
    setShowUpdateOrganizationDialog(false);
    setOrganization(null);
  };

  const handleOpenDeleteOrganizationDialog = (organization: IOrganization) => {
    setOrganization(organization)
    setShowDeleteOrganizationDialog(true)
  };

  const handleCloseDeleteOrganizationDialog = () => {
    setShowDeleteOrganizationDialog(false);
    setOrganization(null);
  };

  const [postOrganization] = usePostOrganizationMutation();
  const handleAddOrganization = async (name: string, managerId: string) => {
    await postOrganization({postOrganization: {name, managerId}})
      .unwrap()
      .then((payload) => {
        setShowAddOrganizationDialog(false)
      })
      .catch((error) => {
        setSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowSnackbar(true)
      })
  };

  const [updateOrganization] = usePutOrganizationMutation();
  const handleUpdateOrganization = async (organizationId: number, name: string, managerId: string) => {
    await updateOrganization({organizationId, putOrganization: {name, managerId}})
      .unwrap()
      .then((payload) => {
        setShowUpdateOrganizationDialog(false)
      })
      .catch((error) => {
        setSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowSnackbar(true)
      })
  };

  const [deleteOrganization] = useDeleteOrganizationMutation();
  const handleDeleteOrganization = async (organizationId: string) => {
      await deleteOrganization({organizationId: organizationId})
      .unwrap()
      .then((payload) => {
        setShowDeleteOrganizationDialog(false)
      })
      .catch((error) => {
        setSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowSnackbar(true)
      })
  };

  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>();

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
    setSnackbarMessage(null)
  };

  const columns = useMemo(
    () => [
      {
        Header: t('id', 'Id'),
        accessor: 'id'
      },
      {
        Header: t('name', 'Name'),
        accessor: 'name',
        Cell: ({ row }: { row: Row }) => {
          const { values } = row;
          return (
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Stack spacing={0}>
                <Typography>{values.name}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {values.id}
                </Typography>
              </Stack>
            </Stack>
          );
        }
      },
      {
        Header: t('manager', 'Manager'),
        accessor: 'managerId',
        Cell: ({ row }: { row: Row }) => {
          const manager = allUsers?.find(x => x.id == row.values.managerId)

          return (
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Stack spacing={0}>
                <Typography>{manager?.firstName} {manager?.lastName}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {manager?.id}
                </Typography>
              </Stack>
            </Stack>
          );
        }
      },
      {
        Header: t('actions', 'Actions'),
        className: 'cell-center',
        disableSortBy: true,
        Cell: ({ row }: { row: Row<{}> }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Tooltip title={t('edit', 'Edit')}>
                <IconButton
                  color="primary"
                  onClick={() => handleOpenUpdateOrganizationDialog(row.original)}
                >
                  <EditTwoTone twoToneColor={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('delete', 'Delete')}>
                <IconButton
                  color="error"
                  onClick={() => handleOpenDeleteOrganizationDialog(row.original)}
                >
                  <DeleteTwoTone twoToneColor={theme.palette.error.main} />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme, allUsers, t]
  );
  
  return (
    <MainCard content={false}>
      <ScrollX>
        <OrganizationsTable
          columns={columns}
          organizations={organizations === undefined ? [] : organizations}
          handleOpenAddOrganizationDialog={handleOpenAddOrganizationDialog}
          getHeaderProps={(column: HeaderGroup) => column.getSortByToggleProps()}
          t={t}
          isLoading={isLoadingOrganizations || isLoadingUsers}
          isFetching={isFetchingOrganizations || isFetchingUsers}
        />
      </ScrollX>
      {showDeleteOrganizationDialog &&
        <GenericDeleteDialog
          openDialog={showDeleteOrganizationDialog}
          handleClose={handleCloseDeleteOrganizationDialog}
          title={t('do_you_want_to_delete_x', 'Do you want to delete {0}?').replace('{0}', organization?.name)}
          message={t('this_action_cannot_be_undone', 'This action cannot be undone.')}
          handleDelete={() => handleDeleteOrganization(organization?.id)}
        />
      }

      {showAddOrganizationDialog && <AddOrganizationDialog managers={allUsers!!} openDialog={showAddOrganizationDialog} handleClose={handleCloseAddOrganizationDialog} handleAddOrganization={handleAddOrganization} />}
      
      {showUpdateOrganizationDialog && organization && <UpdateOrganizationDialog openDialog={showUpdateOrganizationDialog} managers={allUsers!!} handleClose={handleCloseUpdateOrganizationDialog} handleUpdateOrganization={handleUpdateOrganization} organization={organization} />}
    
      <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>{snackbarMessage}</Alert>
      </Snackbar>
    </MainCard>
  )
}

// ==============================|| REACT TABLE ||============================== //

interface Props {
    columns: Column[]
    organizations: IOrganization[]
    handleOpenAddOrganizationDialog: () => void
    getHeaderProps: (column: HeaderGroup) => {}
    t: TFunction<"translation", undefined, "translation">
    isLoading: boolean
    isFetching: boolean
}

function OrganizationsTable({ columns, organizations, handleOpenAddOrganizationDialog, getHeaderProps, t, isLoading, isFetching }: Props) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const filterTypes = useMemo(() => renderFilterTypes, []);
  const sortBy = { id: 'fatherName', desc: false };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { globalFilter, pageIndex, pageSize },
    preGlobalFilteredRows,
    setGlobalFilter
  } = useTable(
    {
    columns,
    data: organizations,
    filterTypes,
    initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: ['id'], sortBy: [sortBy] }
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  return (
    <Stack spacing={3}>
      <Stack
        direction={matchDownSM ? 'column' : 'row'}
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 3, pb: 0 }}
        >
        <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            size="small"
        />
        <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={1}>
            <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleOpenAddOrganizationDialog} size="small" sx={{textTransform: 'none'}}>
            {t('add_organization', 'Add organization')}
            </Button>
        </Stack>
      </Stack>
      <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup: HeaderGroup<{}>) => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: HeaderGroup) => (
            <TableCell {...column.getHeaderProps([{ className: column.className }, getHeaderProps(column)])}>
                <HeaderSort column={column} />
            </TableCell>
            ))}
        </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {
          isLoading ? (
            <EmptyTable msg={t('loading', 'Loading...')} colSpan={7} />
          ) : isFetching ? (
            <EmptyTable msg={t('fetching', 'Fetching...')} colSpan={7} />
          ) : page.length > 0 ? (
            page.map((row: Row, i: number) => {
            prepareRow(row);

            return (
              <Fragment key={i}>
              <TableRow
                {...row.getRowProps()}
                sx={{ cursor: 'pointer', bgcolor: 'inherit' }}
              >
                {row.cells.map((cell: Cell) => (
                  <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</TableCell>
                ))}
              </TableRow>
              {row.isExpanded}
              </Fragment>
            );
          })
          ) : (
            <EmptyTable msg={t('no_data_found', 'Data not found')} colSpan={7} />
          )
        }
        <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
        <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
            <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
        </TableCell>
        </TableRow>
      </TableBody>
      </Table>
    </Stack>
  );
}