import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControl, InputLabel, MenuItem, Select, Autocomplete, Box, Stack, Typography, FormHelperText } from "@mui/material";
import { IExternalDevice } from "models/IExternalDevice";
import { IOrganization } from "models/IOrganization";
import { IUser } from "models/IUser";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { DeviceBrandId } from "models/ISensor";
import { useTranslation } from "react-i18next";
import { getDeviceBrandName } from "helpers/ExternalDeviceExtensions";

export type IUpdateExternalDeviceDialogProps = {
    openDialog: boolean;
    organizations: IOrganization[];
    users: IUser[];
    handleClose: () => void;
    externalDevice: IExternalDevice;
    handleUpdateExternalDevice: (externalDeviceId: string, brandId: string | null, organizationId: number | null, assignedUserId: string | null, meta: string | null) => void;
};

export default function UpdateUserDialog({ openDialog, organizations, users, handleClose, externalDevice, handleUpdateExternalDevice }: IUpdateExternalDeviceDialogProps) {
    const { t } = useTranslation();
    
    const handleUpdateDialogClose: (event: any, reason: any) => void = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        handleClose();
    };

    const validationSchema = yup.object({
        id: yup.string().required(t('field_required	', 'This field is required.')!!),
        brandId: yup.string().required(t('field_required	', 'This field is required.')!!),
        organization: yup.object().required(t('field_required	', 'This field is required.')!!)
    });

    const formik = useFormik({
        initialValues: {
            id: externalDevice.id!!,
            brandId: externalDevice.brandId!!,
            organization: organizations.find(x => x.id == externalDevice.organizationId),
            user: users.find(x => x.id == externalDevice.assignedUserId) == undefined ? null : users.find(x => x.id == externalDevice.assignedUserId),
            meta: externalDevice.meta == undefined ? '' : externalDevice.meta
        },
        validationSchema,
        onSubmit: (values) => {
            handleUpdateExternalDevice(
                values.id,
                values.brandId,
                values.organization!!.id!!, 
                values.user == null ? null : values.user.id!!,
                values.meta == '' ? null : values.meta
            )
            handleClose();
        }
      });

    return (
        <Dialog
            open={openDialog}
            onClose={handleUpdateDialogClose}
        >
            <form noValidate onSubmit={formik.handleSubmit}>
                <DialogTitle>{t('edit_device', 'Edit device')}</DialogTitle>
                <DialogContent>
                    <TextField
                        id="id"
                        name="id"
                        autoFocus
                        margin="normal"
                        label={t('id', 'Id')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formik.values.id}
                        onChange={formik.handleChange}
                        error={formik.touched.id && Boolean(formik.errors.id)}
                        helperText={formik.touched.id && formik.errors.id}
                        required
                        disabled
                    />

                    <FormControl margin="normal" fullWidth>
                        <InputLabel 
                            id="brandId-select-label"
                            error={formik.touched.brandId && Boolean(formik.errors.brandId)}
                            required>
                                {t('brand', 'Brand')}
                        </InputLabel>
                        <Select
                            id="brandId"
                            name="brandId"
                            labelId="brandId-select-label"
                            label={t('brand', 'Brand')}
                            value={formik.values.brandId}
                            onChange={formik.handleChange}
                        >
                                <MenuItem value={DeviceBrandId.Fitbit}>{getDeviceBrandName(DeviceBrandId.Fitbit)}</MenuItem>
                                <MenuItem value={DeviceBrandId.Flow}>{getDeviceBrandName(DeviceBrandId.Flow)}</MenuItem>
                                <MenuItem value={DeviceBrandId.CapMedic}>{getDeviceBrandName(DeviceBrandId.CapMedic)}</MenuItem>
                                <MenuItem value={DeviceBrandId.WoePal}>{getDeviceBrandName(DeviceBrandId.WoePal)}</MenuItem>
                                <MenuItem value={DeviceBrandId.SakuraTech}>{getDeviceBrandName(DeviceBrandId.SakuraTech)}</MenuItem>
                                <MenuItem value={DeviceBrandId.inBiot}>{getDeviceBrandName(DeviceBrandId.inBiot)}</MenuItem>
                                <MenuItem value={DeviceBrandId.AwarenessApp}>{getDeviceBrandName(DeviceBrandId.AwarenessApp)}</MenuItem>
                                <MenuItem value={DeviceBrandId.DSS2}>{getDeviceBrandName(DeviceBrandId.DSS2)}</MenuItem>
                                <MenuItem value={DeviceBrandId.CEMIC}>{getDeviceBrandName(DeviceBrandId.CEMIC)}</MenuItem>
                                <MenuItem value={DeviceBrandId.PolitoAndSAT}>{getDeviceBrandName(DeviceBrandId.PolitoAndSAT)}</MenuItem>
                                <MenuItem value={DeviceBrandId.Sykno}>{getDeviceBrandName(DeviceBrandId.Sykno)}</MenuItem>
                                <MenuItem value={DeviceBrandId.Fibaro}>{getDeviceBrandName(DeviceBrandId.Fibaro)}</MenuItem>
                                <MenuItem value={DeviceBrandId.AlertiT}>{getDeviceBrandName(DeviceBrandId.AlertiT)}</MenuItem>
                                <MenuItem value={DeviceBrandId.Unknown}>{getDeviceBrandName(DeviceBrandId.Unknown)}</MenuItem>
                                <MenuItem value={DeviceBrandId.Anura}>{getDeviceBrandName(DeviceBrandId.Anura)}</MenuItem>
                        </Select>
                        {formik.touched.brandId && formik.errors.brandId && (
                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                {' '}
                                {formik.errors.brandId}{' '}
                            </FormHelperText>
                        )}
                    </FormControl>
                    
                    <FormControl margin="normal" fullWidth>
                        <Autocomplete
                            fullWidth
                            id="organization-autocomplete-label"
                            options={organizations!!}
                            value={formik.values.organization}
                            onChange={(event, organization) => formik.setFieldValue('organization', organization)}
                            getOptionLabel={(organization) => {
                                if (organizations) {
                                    const exist = organizations.includes(organization)
                                    if (exist)
                                        return organization.name!!
                                }
                                return ''
                            }
                            }
                            renderInput={(params) => 
                                <TextField
                                    {...params}
                                    name="organization"
                                    error={formik.touched.organization && Boolean(formik.errors.organization)}
                                    helperText={(formik.touched.organization && formik.errors.organization) ? <>{formik.errors.organization}</> : null}
                                    label={t('organization', 'Organization')}
                                    required
                                />
                            }
                            renderOption={(props, organization) => (
                                <Box component="li" {...props}>
                                    <Stack direction="row" spacing={1.5} alignItems="center">
                                        <Stack spacing={0}>
                                            <Typography>{organization?.name}</Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                {organization?.id}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                            )}
                        />
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                        <Autocomplete
                            fullWidth
                            id="assignedUser-autocomplete-label"
                            options={users!!}
                            value={formik.values.user}
                            onChange={(event, user) => formik.setFieldValue('user', user)}
                            getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
                            renderInput={(params) => 
                                <TextField {...params} label={t('assigned_user', 'Assigned user')} />
                            }
                            renderOption={(props, user) => (
                                <Box component="li" {...props}>
                                    <Stack direction="row" spacing={1.5} alignItems="center">
                                        <Stack spacing={0}>
                                            <Typography>{user?.firstName} {user?.lastName}</Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                {user?.id}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                            )}
                        />
                    </FormControl>

                    <TextField
                        id="meta"
                        name="meta"
                        margin="normal"
                        label={t('meta', 'Meta')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formik.values.meta}
                        onChange={formik.handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleUpdateDialogClose(null, null)} variant="outlined" color="secondary">{t('cancel', 'Cancel')}</Button>
                    <Button type="submit" variant="outlined">{t('save', 'Save')}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}