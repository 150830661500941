// types
import { PaletteThemeProps } from 'types/theme';
import { PalettesProps } from '@ant-design/colors';
import { ThemeMode, PresetColor } from 'types/config';
import CHAMPTheme from './CHAMPTheme';
import KORETheme from './KORETheme';
import AIQReadyTheme from './AIQReadyTheme';
import SMILETheme from './SMILETheme';
import AURATheme from './AURATheme';
import VETLLARTheme from './VETLLARTheme';
import ICARETheme from './ICARETheme';


// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (colors: PalettesProps, presetColor: PresetColor, mode: ThemeMode): PaletteThemeProps => {
  switch (presetColor) {
    case 'CHAMPTheme':
      return CHAMPTheme(colors)
    case 'KORETheme':
      return KORETheme(colors)
    case 'AIQReadyTheme':
      return AIQReadyTheme(colors)
    case 'SMILETheme':
      return SMILETheme(colors)
    case 'AURATheme':
      return AURATheme(colors)
    case 'VETLLARTheme':
      return VETLLARTheme(colors)
    case 'ICARETheme':
      return ICARETheme(colors)
    default:
      return CHAMPTheme(colors)
  }
};

export default Theme;
