import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography } from "@mui/material";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import { IExternalDevice } from "models/IExternalDevice";

export type IUploadExternalDeviceDataDialogProps = {
    openDialog: boolean;
    externalDevice: IExternalDevice;
    handleClose: () => void;
    handleUploadExternalDeviceData: (externalDeviceId: string, file: File) => void;
};

export default function UploadExternalDeviceDataDialog({ openDialog, externalDevice, handleClose, handleUploadExternalDeviceData }: IUploadExternalDeviceDataDialogProps) {
    const { t } = useTranslation();
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);

    const handleAddDialogClose: (event: any, reason: any) => void = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        handleClose();
    };

    const validationSchema = yup.object({
        id: yup.string().required(t('field_required', 'This field is required.')!!),
        file: yup.mixed().required(t('file_required', 'A CSV file is required.')!!)
    });

    const formik = useFormik({
        initialValues: {
            id: externalDevice.id!!,
            file: uploadedFile
        },
        validationSchema,
        onSubmit: (values) => {
            handleUploadExternalDeviceData(values.id, uploadedFile!!)
            handleClose()
        }
    });

    const onDrop = (acceptedFiles: File[]) => {
        const file = acceptedFiles.find((file) => file.type === "text/csv");
        if (file) {
            setUploadedFile(file);
            formik.setFieldValue("file", file);
        } else {
            alert(t('invalid_file_type', 'Invalid file type. Please upload a CSV file.'));
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { 'text/csv': ['.csv'] },
        multiple: false
    });

    return (
        <Dialog
            open={openDialog}
            onClose={handleAddDialogClose}
            fullWidth
            maxWidth="sm"
            sx={{ '& .MuiDialog-paper': { width: '100%', maxWidth: '600px' } }}
        >
            <form noValidate onSubmit={formik.handleSubmit}>
                <DialogTitle>{t('upload_data', 'Upload data')}</DialogTitle>
                <DialogContent>
                    <Typography sx={{ mb: 2 }}>
                        {t('data_upload_warning', 'The data upload may take a few minutes, depending on the file size. Once you click "Save", this popup will close and the process will run in the background. The data will appear on the graph as it is saved.')}
                    </Typography>
                    <Box
                        {...getRootProps()}
                        sx={{
                            border: '2px dashed #ccc',
                            borderRadius: '4px',
                            padding: '20px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            backgroundColor: isDragActive ? '#f0f0f0' : '#fafafa',
                            width: '100%', 
                            height: '150px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxSizing: 'border-box',
                        }}
                    >
                        <input {...getInputProps()} />
                        {uploadedFile ? (
                            <Typography>{t('file_uploaded', 'File uploaded')}: {uploadedFile.name}</Typography>
                        ) : (
                            <Typography>
                                {isDragActive
                                    ? t('drop_file', 'Drop the file here...')
                                    : t('drag_drop_or_click', 'Drag and drop a CSV file here, or click to select one.')}
                            </Typography>
                        )}
                    </Box>
                    {formik.errors.file && formik.touched.file && (
                        <Typography color="error" sx={{ mt: 1 }}>
                            {formik.errors.file}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleAddDialogClose(null, null)} variant="outlined" color="secondary">{t('cancel', 'Cancel')}</Button>
                    <Button type="submit" variant="outlined">{t('save', 'Save')}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}    