import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Autocomplete, Box, FormControl, Stack, Typography } from "@mui/material";
import { IOrganization } from "models/IOrganization";
import { IUser } from "models/IUser";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from "react-i18next";

export type IUpdateOrganizationDialogProps = {
    openDialog: boolean;
    managers: IUser[];
    handleClose: () => void;
    organization: IOrganization;
    handleUpdateOrganization: (organizationId: number, name: string, managerId: string) => void;
};

export default function UpdateOrganizationDialog({ openDialog, managers, handleClose, organization, handleUpdateOrganization }: IUpdateOrganizationDialogProps) {
    const { t } = useTranslation();

    const handleUpdateDialogClose: (event: any, reason: any) => void = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        handleClose();
    };

    const validationSchema = yup.object({
        name: yup.string().required(t('field_required', 'This field is required.')!!),
        manager: yup.object().required(t('field_required', 'This field is required.')!!)
    });

    const formik = useFormik({
        initialValues: {
            name: organization.name!!,
            manager: managers.find(x => x.id == organization.managerId)
        },
        validationSchema,
        onSubmit: (values) => {
            handleUpdateOrganization(
                organization.id!!,
                values.name,
                values.manager!!.id!!
            )
        }
    });

    return (
        <Dialog
            open={openDialog}
            onClose={handleUpdateDialogClose}
        >
            <form noValidate onSubmit={formik.handleSubmit}>
                <DialogTitle>{t('edit_organization', 'Edit organization')}</DialogTitle>
                <DialogContent>
                    <TextField
                        id="name"
                        name="name"
                        margin="normal"
                        label={t('name', 'Name')}
                        type="text"
                        fullWidth
                        variant="outlined"
                        required
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                    />

                    <FormControl margin="normal" fullWidth>
                        <Autocomplete
                            fullWidth
                            id="manager-autocomplete-label"
                            options={managers!!}
                            value={formik.values.manager}
                            onChange={(event, manager) => formik.setFieldValue('manager', manager)}
                            getOptionLabel={(manager) => `${manager.firstName} ${manager.lastName}` }
                            renderInput={(params) => 
                                <TextField
                                    {...params}
                                    name="manager"
                                    error={formik.touched.manager && Boolean(formik.errors.manager)}
                                    helperText={(formik.touched.manager && formik.errors.manager) ? <>{formik.errors.manager}</> : null}
                                    label={t('manager', 'Manager')}
                                    required
                                />
                            }
                            renderOption={(props, manager) => (
                                <Box component="li" {...props}>
                                    <Stack direction="row" spacing={1.5} alignItems="center">
                                        <Stack spacing={0}>
                                            <Typography>{manager?.firstName} {manager?.lastName}</Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                {manager?.id}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Box>
                            )}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleUpdateDialogClose(null, null)} variant="outlined" color="secondary">{t('cancel', 'Cancel')}</Button>
                    <Button type="submit" variant="outlined">{t('save', 'Save')}</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}