// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import authSlice from 'features/auth/authSlice';
import { apiSlice } from 'features/api/apiSlice';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  auth: authSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export default reducers;
