import { useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { apiSlice, useLogoutMutation } from 'features/api/apiSlice';
import { localLogout } from 'features/auth/authSlice'
import { useAppDispatch } from "app/hooks";

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
  setOpen: (open: boolean) => void;
}

const ProfileTab = ({ handleLogout, setOpen }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const [logout] = useLogoutMutation();
  const dispatch = useAppDispatch();

  const [selectedIndex, setSelectedIndex] = useState(() => { 
    if (location.pathname === "/profile")
      return 0
    else
      return 3 
  });
  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number, route: string) => {
    setSelectedIndex(index);
    navigate(route);
    setOpen(false)
  };

  const handleUserLogout = async () => {
    try {
      await logout().unwrap();
      dispatch(localLogout());
      dispatch(apiSlice.util.resetApiState());
      navigate("/");
    } catch {
      console.log("Error logging out");
    }
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      <ListItemButton selected={selectedIndex === 0} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 0, '/profile')}>
        <ListItemIcon>
          <SettingOutlined />
        </ListItemIcon>
        <ListItemText primary={t('settings', 'Settings')} />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 2} onClick={handleUserLogout}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary={t('logout', 'Logout')} />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
