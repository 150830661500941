import { useAppSelector } from "app/hooks";
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { NotificationsTable } from "components/tables/NotificationsTable";
import { useGetNotificationsQuery, useGetUsersQuery } from "features/api/apiSlice";
import { selectCurrentUser } from "features/auth/authSlice";
import { UserRole } from "models/IUser";
import moment from "moment";

export default function NotificationsPage() {
  const authUser = useAppSelector(selectCurrentUser);
  
  const { data: notifications, isLoading, isFetching } = useGetNotificationsQuery({
    from: null,
    to: moment.utc().endOf("day").toDate().toISOString(),
    readByClinician: null
  })
  const { data: patients } = useGetUsersQuery({ parentId: authUser!!.id!!, role: UserRole.Patient })

  return (
    <MainCard content={false}>
      <ScrollX>
        <NotificationsTable notifications={notifications === undefined ? [] : notifications} patients={patients === undefined ? [] : patients} isSingleUser={false} isLoading={isLoading} isFetching={isFetching} />
      </ScrollX>
    </MainCard>
  )
}