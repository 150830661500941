// material-ui
import { Theme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

// project import
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';

import useConfig from 'hooks/config/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';
import LanguageSelector from 'components/selectors/LanguageSelector';

// types
import { MenuOrientation } from 'types/config';

import { selectCurrentUser } from "features/auth/authSlice";
import { useAppSelector } from 'app/hooks';
import { UserProviderId, UserRole } from 'models/IUser';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { menuOrientation } = useConfig();

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  const authUser = useAppSelector(selectCurrentUser);

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
      <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}></Box>
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
      <LanguageSelector />
      {((authUser!!.role === UserRole.Manager || authUser!!.role === UserRole.Clinician) && authUser!!.provider !== UserProviderId.SMILE && authUser!!.provider !== UserProviderId.VETLLAR) && <Notification />}
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
