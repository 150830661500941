import { Box, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGetLanguagesQuery } from "features/api/apiSlice";

const LanguageSelector = () => {
    const { i18n } = useTranslation();

    const { data: languages } = useGetLanguagesQuery();

    const handleLanguageChange = (event: SelectChangeEvent) => {
        i18n.changeLanguage(event.target.value);
    };

    return languages ? (
        <Select size="small" variant="outlined" value={i18n.language} onChange={handleLanguageChange} sx={{ width: "180px" }}>
            {languages?.map((language) => {
                return (
                    <MenuItem value={language.isoCode} key={language.isoCode} >
                        <Stack direction="row" spacing={2}>
                            <Box component='img' src={`/images/flags/${language.flagIconCode}.svg`} maxWidth={20} />
                            <Typography>{language.name}</Typography>
                        </Stack>
                    </MenuItem>
                )
            })}
        </Select>
    ) : null;    
}

export default LanguageSelector;