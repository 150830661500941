// material-ui
import { useTheme } from '@mui/material/styles';
import { Alert, Backdrop, Box, Button, CircularProgress, FormLabel, Grid, Snackbar, Stack, TextField, Typography } from '@mui/material';

// project import
import Avatar from 'components/@extended/Avatar';
import MainCard from 'components/MainCard';

// assets
import { CameraOutlined } from '@ant-design/icons';

// types
import { ThemeMode } from 'types/config';
import { getAvatar, getUserRoleName } from 'helpers/UserExtensions';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { profileChanged, selectCurrentUser } from 'features/auth/authSlice';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { useGetUserParentQuery, usePatchUserMutation } from 'features/api/apiSlice';
import { useState } from 'react';
import React from 'react';

// ==============================|| ACCOUNT PROFILE - PERSONAL ||============================== //

const TabProfile = () => {
  const { t } = useTranslation();
  
  const authUser = useAppSelector(selectCurrentUser)!!;
  const dispatch = useAppDispatch();

  const [patchUser, patchUserResult] = usePatchUserMutation();

  const theme = useTheme();

  const { data: parent/*, isLoading: isLoadingPatient, isFetching: isFetchingPatient*/ } = useGetUserParentQuery(authUser!!.id!!)

  const validationSchema = yup.object({
    firstName: yup.string().required(t('field_required', 'This field is required.')!!),
    lastName: yup.string().required(t('field_required', 'This field is required.')!!),
    email: yup.string().email(t('wrong_email_format', 'Wrong email format.')!!).required(t('field_required', 'This field is required.')!!)
  });

  const formik = useFormik({
      initialValues: {
          firstName: authUser!!.firstName!!,
          lastName: authUser!!.lastName!!,
          email: authUser!!.email!!
      },
      validationSchema,
      onSubmit: async (values) => {
        await patchUser({
            userId: authUser!!.id!!,
            patchUser: {
                fields: ["FirstName", "LastName", "Email"],
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email
            }
        }).unwrap()
        .then((payload) => {
            
        })
        .catch((error) => {
            setSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
            setShowSnackbar(true)
        });
        
        dispatch(profileChanged({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email
        }));
      }
  });

  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>();

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
    setSnackbarMessage(null)
  };

  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MainCard title={t('personal_information', 'Personal information')}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack spacing={2.5} alignItems="center" sx={{ m: 3 }}>
                    <FormLabel
                      htmlFor="change-avtar"
                      sx={{
                        position: 'relative',
                        borderRadius: '50%',
                        overflow: 'hidden',
                        '&:hover .MuiBox-root': { opacity: 1 },
                        cursor: 'pointer'
                      }}
                    >
                      <Avatar alt="Avatar 1" src={getAvatar(authUser.id!!)} sx={{ width: 76, height: 76 }} />
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          backgroundColor: theme.palette.mode === ThemeMode.DARK ? 'rgba(255, 255, 255, .75)' : 'rgba(0,0,0,.65)',
                          width: '100%',
                          height: '100%',
                          opacity: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <Stack spacing={0.5} alignItems="center">
                          <CameraOutlined style={{ color: theme.palette.secondary.lighter, fontSize: '1.5rem' }} />
                          <Typography sx={{ color: 'secondary.lighter' }} variant="caption">
                            {t('upload', 'Upload')}
                          </Typography>
                        </Stack>
                      </Box>
                    </FormLabel>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1.25}>
                    <TextField
                      id="firstName"
                      name="firstName"
                      autoFocus
                      margin="normal"
                      label={t('first_name', 'First name')}
                      type="text"
                      fullWidth
                      variant="outlined"
                      required
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                      helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1.25}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    margin="normal"
                    label={t('last_name', 'Last name')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    required
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                  />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1.25}>
                  <TextField
                    id="parent"
                    name="parent"
                    margin="normal"
                    label={t('parent', 'Parent')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={parent?.firstName + " " + parent?.lastName}
                    disabled
                  />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={1.25}>
                  <TextField
                    id="role"
                    name="role"
                    margin="normal"
                    label={t('role', 'Role')}
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={getUserRoleName(authUser.role)}
                    disabled
                  />
                  </Stack>
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MainCard title={t('contact_information', 'Contact information')}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Stack spacing={1.25}>
                        <TextField
                          id="email"
                          name="email"
                          margin="normal"
                          label={t('email', 'Email')}
                          type="email"
                          fullWidth
                          variant="outlined"
                          required
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={formik.touched.email && Boolean(formik.errors.email)}
                          helperText={formik.touched.email && formik.errors.email}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </MainCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              <Button type="submit" variant="contained">{t('save', 'Save')}</Button>
            </Stack>
          </Grid>
        </Grid>
      </form>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={patchUserResult.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>{snackbarMessage}</Alert>
      </Snackbar>
    </>
  );
};

export default TabProfile;
