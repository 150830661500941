import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Fragment, useMemo, useState } from "react";
import AddUserDialog from "components/dialogs/Users/AddUserDialog";
import UpdateUserDialog from "components/dialogs/Users/UpdateUserDialog";
import { Alert, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Cell, Column, HeaderGroup, Row, useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { TFunction } from "i18next";
import { GlobalFilter, renderFilterTypes } from "utils/react-table";
import { DeleteTwoTone, EditTwoTone, PlusOutlined } from "@ant-design/icons";
import { EmptyTable, HeaderSort, TablePagination } from "components/third-party/ReactTable";
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { getUserRoleName } from "helpers/UserExtensions";
import React from "react";
import GenericDeleteDialog from "components/dialogs/Generic/GenericDeleteDialog";
import { useAppSelector } from "app/hooks";
import { useGetUsersQuery, usePostUserMutation, usePutUserMutation, useDeleteUserMutation } from "features/api/apiSlice";
import { selectCurrentUser } from "features/auth/authSlice";
import { IUser, UserRole } from "models/IUser";
import { useTranslation } from "react-i18next";

// ==============================|| USERS PAGE ||============================== //

export default function UsersPage() {
  const { t } = useTranslation();
  const theme = useTheme();

  const authenticatedUser = useAppSelector(selectCurrentUser)
  const { data: childrenUsers, isLoading: isLoadingUsers, isFetching: isFetchingUsers } = useGetUsersQuery({ parentId: authenticatedUser!!.id!!, role: null })
  const allUsers = useMemo(() => childrenUsers?.concat(authenticatedUser!!), [childrenUsers, authenticatedUser])

  const [user, setUser] = useState<any>(null);
  const [showAddUserDialog, setShowAddUserDialog] = useState<boolean>(false);
  const [showUpdateUserDialog, setShowUpdateUserDialog] = useState<boolean>(false);
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState<boolean>(false);

  const handleOpenAddUserDialog = () => {
    setShowAddUserDialog(true)
  };

  const handleCloseAddUserDialog = () => {
    setShowAddUserDialog(false);
  };

  const handleOpenUpdateUserDialog = (user: IUser) => {
    setUser(user)
    setShowUpdateUserDialog(true)
  };

  const handleCloseUpdateUserDialog = () => {
    setShowUpdateUserDialog(false);
    setUser(null);
  };

  const handleOpenDeleteUserDialog = (user: IUser) => {
    setUser(user)
    setShowDeleteUserDialog(true)
  };

  const handleCloseDeleteUserDialog = () => {
    setShowDeleteUserDialog(false);
    setUser(null);
  };

  const [postUser] = usePostUserMutation();
  const handleAddUser = async (firstName: string, lastName: string, email: string, password: string, parentId: string, role: UserRole, culture: string | null, enrolmentDate: string | null, provider: string | null, meta: string | null) => {
    await postUser({firstName, lastName, email, password, parentId, role, culture, enrolmentDate, provider, meta})
      .unwrap()
      .then((payload) => {
        setShowAddUserDialog(false);
      })
      .catch((error) => {
        setSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowSnackbar(true)
      })
  };

  const [putUser] = usePutUserMutation();
  const handleUpdateUser = async (userId: string, firstName: string, lastName: string, email: string, culture: string | null, parentId: string, enrolmentDate: string | null, meta: string | null) => {
    await putUser({userId, putUser: {firstName, lastName, email, culture, parentId, enrolmentDate, meta}})
      .unwrap()
      .then((payload) => {
        setShowUpdateUserDialog(false);
      })
      .catch((error) => {
        setSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
        setShowSnackbar(true)
      })
  };

  const [deleteUser] = useDeleteUserMutation();
  const handleDeleteUser = async (userId: string) => {
      await deleteUser(userId)
        .unwrap()
        .then((payload) => {
          setShowDeleteUserDialog(false);
        })
        .catch((error) => {
          setSnackbarMessage(t(error.data.literalKey as string, error.data.message as string))
          setShowSnackbar(true)
        })
  };

  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>();

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
    setSnackbarMessage(null)
  };

  const columns = useMemo(
    () => [
      {
        Header: t('id', 'Id'),
        accessor: 'id'
      },
      {
        Header: t('name', 'Name'),
        accessor: 'firstName',
        Cell: ({ row }: { row: Row }) => {
          const { values } = row;
          return (
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Stack spacing={0}>
                <Typography>{values.firstName} {values.lastName}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {values.id}
                </Typography>
              </Stack>
            </Stack>
          );
        }
      },
      {
        Header: t('last_name', 'Last name'),
        accessor: 'lastName'
      },
      {
        Header: t('email', 'Email'),
        accessor: 'email'
      },
      {
        Header: t('role', 'Role'),
        accessor: 'role',
        Cell: ({ value }: { value: UserRole }) => {
          switch (value) {
            case UserRole.Admin:
              return <Typography>{getUserRoleName(UserRole.Admin)}</Typography>;
            case UserRole.Manager:
              return <Typography>{getUserRoleName(UserRole.Manager)}</Typography>;
            case UserRole.Clinician:
              return <Typography>{getUserRoleName(UserRole.Clinician)}</Typography>;
            case UserRole.Patient:
              return <Typography>{getUserRoleName(UserRole.Patient)}</Typography>;
            default:
              return <Typography>{t('unknown', 'Unknown')}</Typography>;
          }
        }
      },
      {
        Header: t('parent', 'Parent'),
        accessor: 'parentId',
        Cell: ({ row }: { row: Row }) => {
          const parent = allUsers?.find(x => x.id == row.values.parentId)

          return (
            <Stack direction="row" spacing={1.5} alignItems="center">
              <Stack spacing={0}>
                <Typography>{parent?.firstName} {parent?.lastName}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {parent?.id}
                </Typography>
              </Stack>
            </Stack>
          );
        }
      },
      {
        Header: t('actions', 'Actions'),
        className: 'cell-center',
        disableSortBy: true,
        Cell: ({ row }: { row: Row<{}> }) => {
          return (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={0}>
              <Tooltip title={t('edit', 'Edit')}>
                <IconButton
                  color="primary"
                  onClick={() => handleOpenUpdateUserDialog(row.original)}
                >
                  <EditTwoTone twoToneColor={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('delete', 'Delete')}>
                <IconButton
                  color="error"
                  onClick={() => handleOpenDeleteUserDialog(row.original)}
                >
                  <DeleteTwoTone twoToneColor={theme.palette.error.main} />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme, allUsers, t]
  );

  return (
    <>
      <MainCard content={false}>
        <ScrollX>
          <UsersTable
            columns={columns}
            users={childrenUsers === undefined ? [] : childrenUsers}
            handleOpenAddUserDialog={handleOpenAddUserDialog}
            getHeaderProps={(column: HeaderGroup) => column.getSortByToggleProps()}
            t={t}
            isLoading={isLoadingUsers}
            isFetching={isFetchingUsers}
          />
        </ScrollX>
        {showDeleteUserDialog &&
            <GenericDeleteDialog
                openDialog={showDeleteUserDialog}
                handleClose={handleCloseDeleteUserDialog}
                title={t('do_you_want_to_delete_x', 'Do you want to delete {0}?').replace('{0}', user?.id)}
                message={t('this_action_cannot_be_undone', 'This action cannot be undone.')}
                handleDelete={() => handleDeleteUser(user?.id)}
            />
        }
        
        {showAddUserDialog && <AddUserDialog users={allUsers!!} openDialog={showAddUserDialog} authenticatedUser={authenticatedUser!!} handleClose={handleCloseAddUserDialog} handleAddUser={handleAddUser} />}
        
        {showUpdateUserDialog && user && <UpdateUserDialog openDialog={showUpdateUserDialog} user={user} users={allUsers!!} handleClose={handleCloseUpdateUserDialog} handleUpdateUser={handleUpdateUser}  />}
        
        <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center'}}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>{snackbarMessage}</Alert>
        </Snackbar>
      </MainCard>
    </>
  )
}

// ==============================|| REACT TABLE ||============================== //

interface Props {
    columns: Column[]
    users: IUser[]
    handleOpenAddUserDialog: () => void
    getHeaderProps: (column: HeaderGroup) => {}
    t: TFunction<"translation", undefined, "translation">
    isLoading: boolean
    isFetching: boolean
}

function UsersTable({ columns, users, handleOpenAddUserDialog, getHeaderProps, t, isLoading, isFetching }: Props) {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    const filterTypes = useMemo(() => renderFilterTypes, []);
    const sortBy = { id: 'fatherName', desc: false };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        rows,
        page,
        gotoPage,
        setPageSize,
        state: { globalFilter, pageIndex, pageSize },
        preGlobalFilteredRows,
        setGlobalFilter
    } = useTable(
        {
        columns,
        data: users,
        filterTypes,
        initialState: { pageIndex: 0, pageSize: 10, hiddenColumns: ['id', 'lastName', 'email'], sortBy: [sortBy] }
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );

  return (
    <Stack spacing={3}>
      <Stack
        direction={matchDownSM ? 'column' : 'row'}
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 3, pb: 0 }}
        >
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          size="small"
        />
        <Stack direction={matchDownSM ? 'column' : 'row'} alignItems="center" spacing={1}>
          <Button variant="contained" startIcon={<PlusOutlined />} onClick={handleOpenAddUserDialog} size="small" sx={{textTransform: 'none'}}>
            {t('add_user', 'Add user')}
          </Button>
        </Stack>
      </Stack>
      <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup: HeaderGroup<{}>) => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: HeaderGroup) => (
            <TableCell {...column.getHeaderProps([{ className: column.className }, getHeaderProps(column)])}>
                <HeaderSort column={column} />
            </TableCell>
            ))}
        </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {
          isLoading ? (
            <EmptyTable msg={t('loading', 'Loading...')} colSpan={7} />
          ) : isFetching ? (
            <EmptyTable msg={t('fetching', 'Fetching...')} colSpan={7} />
          ) : page.length > 0 ? (
            page.map((row: Row, i: number) => {
              prepareRow(row);
    
              return (
                <Fragment key={i}>
                <TableRow
                  {...row.getRowProps()}
                  sx={{ cursor: 'pointer', bgcolor: 'inherit' }}
                  >
                    {row.cells.map((cell: Cell) => (
                    <TableCell {...cell.getCellProps([{ className: cell.column.className }])}>{cell.render('Cell')}</TableCell>
                    ))}
                </TableRow>
                {row.isExpanded}
                </Fragment>
              );
            })
          ) : (
            <EmptyTable msg={t('no_data_found', 'Data not found')} colSpan={7} />
          )
        }
        <TableRow sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
        <TableCell sx={{ p: 2, py: 3 }} colSpan={9}>
            <TablePagination gotoPage={gotoPage} rows={rows} setPageSize={setPageSize} pageSize={pageSize} pageIndex={pageIndex} />
        </TableCell>
        </TableRow>
      </TableBody>
      </Table>
    </Stack>
  )
}