// material-ui
import {
  Avatar,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';

// types
import { IAITag, IDeviceData, IUser, UserProviderId } from 'models/IUser';
import { useNavigate } from 'react-router';
import moment from 'moment';
import 'moment/locale/ca';
import { Box, styled } from '@mui/system';
import React, { useState } from 'react';
import { getDeviceBrandName, getSensorTypeName } from 'helpers/ExternalDeviceExtensions';
import { ISensorData } from 'models/ISensor';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getAvatar } from 'helpers/UserExtensions';
import { useTranslation } from 'react-i18next';


// ==============================|| CUSTOMER - CARD ||============================== //

const PatientCard = ({ patient }: { patient: IUser }) => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const handleClickPatientFile = () => {
    switch(patient.provider) {
      case UserProviderId.SMILE:
        return navigate(`/patients/${patient.id}/smile/file`);
      case UserProviderId.VETLLAR:
        return navigate(`/patients/${patient.id}/vetllar/file`);
      default:
        return navigate(`/patients/${patient.id}/file`)
    }
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: 12,
      border: '1px solid #dadde9',
    },
  }));

  const hasPatientValuesOlderThan24h = () => {
    if (patient.devicesData) {
      return patient.devicesData.some((deviceData: IDeviceData) => {
        return deviceData.sensorsData.some(x => (moment.duration(moment(new Date()).diff(x.lastValueDate.toString())).asHours() > 24 ))
      })
    }

    return false
  }

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  }

  const handleMouseLeave = () => {
    setIsHovered(false);
  }

  return (
    <div 
      onClick={handleClickPatientFile} 
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: 'pointer', boxShadow: isHovered ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none' }} >
      <MainCard sx={{ height: 1, '& .MuiCardContent-root': { height: 1, display: 'flex', flexDirection: 'column' } }}>
        <Grid id="print" container spacing={1.5}>
          <Grid item xs={12}>
            <List sx={{ width: 1, p: 0 }}>
              <ListItem disablePadding>
                <ListItemAvatar
                  style={{ marginRight: "15px" }}>
                  <Avatar src={getAvatar(patient.id!!)} sx={{ width: 60, height: 60, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Box marginBottom={1}>
                      <Typography fontSize={14} fontWeight="bold" lineHeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{patient.lastName}</Typography>
                      <Typography color="grey" fontSize={14} lineHeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{patient.firstName}</Typography>
                    </Box>
                  }
                  secondary={
                    <Box>
                      <Typography color="grey" fontSize={12} lineHeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{t('age', 'Age')}</Typography>
                      <Typography color="grey" fontSize={12} lineHeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">{t('gender', 'Gender')}</Typography>
                    </Box>
                  }
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item wrap="nowrap" spacing={1}>
            <Grid item xs={true} minWidth="20%" lineHeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              <b>{t('id', 'Id')}</b>
            </Grid>
            <Grid item lineHeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              --
            </Grid>
          </Grid>
          <Grid container item wrap="nowrap" spacing={1}>
            <Grid item xs={true} minWidth="20%" lineHeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              <b>{t('enrolment', 'Enrolment')}</b>
            </Grid>
            <Grid item lineHeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {patient.enrolmentDate ? moment(patient.enrolmentDate.toString()).locale('es').format('l') : "--"}
            </Grid>
          </Grid>
          <Grid container item xs={12} wrap="nowrap" spacing={1}>
            <Grid item xs={true} minWidth="20%" lineHeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              <b>{t('last_measurement', 'Last measurement')}</b>
            </Grid>
            <Grid item lineHeight="normal" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              { patient.lastValueDate ? moment(patient.lastValueDate.toString()).locale(i18n.language).fromNow() : "--" }
            </Grid>
            {
              patient.lastValueDate &&
              <Grid item lineHeight="normal" xs="auto">
                <HtmlTooltip title={
                  <React.Fragment>
                    {
                      patient.devicesData!!.map((deviceData: IDeviceData, index: number) => (
                        <Grid container>
                          <Grid item xs={12}><u>{getDeviceBrandName(deviceData.brandId)}</u></Grid>
                          <Grid container item xs={12}>
                            {
                              deviceData.sensorsData.map((sensorData: ISensorData, index: number) => (
                                <Grid item style={(moment.duration(moment(new Date()).diff(sensorData.lastValueDate.toString())).asHours() > 24 ? { color: 'red' } : {} )}>
                                  {getSensorTypeName(sensorData.typeId)} {moment(sensorData.lastValueDate.toString()).locale('es').format('l LTS')}
                                </Grid>
                              ))
                            }
                          </Grid>
                        </Grid>
                      ))
                    }
                  </React.Fragment>
                }>
                  <InfoCircleOutlined style={(hasPatientValuesOlderThan24h() ? { color: 'red'} : {})}/>
                </HtmlTooltip>
              </Grid>
            }
          </Grid>
          { patient.aiTags && 
            <Grid item xs={12}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 0.5,
                    m: 0
                  }}
                  component="ul"
                >
                  {
                    patient.aiTags!!.map((aITag: IAITag, index: number) => (
                      <ListItem disablePadding key={index} sx={{ width: 'auto', pr: 0.75, pb: 0.75 }}>
                        <Chip sx={{ backgroundColor: aITag.color, color: 'white', borderColor: aITag.color }} variant="outlined" size="small" label={t(aITag.literalKey, aITag.literalKey)} />
                      </ListItem>
                    ))
                  }
                </Box>
              </Box>
            </Grid>
          }
        </Grid>
      </MainCard>
    </div>
  );
};

export default PatientCard