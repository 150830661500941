import i18n from 'i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<HttpBackendOptions>({
    fallbackLng: 'en_GB',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
        loadPath: process.env.REACT_APP_API_BASE_URL + '/literals?isocode={{lng}}',
        allowMultiLoading: false,
        crossDomain: true,
        withCredentials: true,
        parse: (data, languages, namespaces) => {
            if (data) {
              const parsedData = JSON.parse(data);

              const translations: {[key: string]: any;} = {};

              parsedData["data"].forEach((entry: any) => {
                translations[entry.key] = entry.value;
              });

              return translations;
            }

            return {};
        },

    }
  });

export default i18n;