import { DeviceBrandName, SensorTypeName, DeviceBrandId, SensorTypeId } from "models/ISensor";

export const AIQReadyTelemetries = {
        [`[${DeviceBrandName.inBiot}] ${SensorTypeName.Temperature}`]: `${DeviceBrandId.inBiot}:${SensorTypeId.Temperature}`,
        [`[${DeviceBrandName.inBiot}] ${SensorTypeName.Humidity}`]: `${DeviceBrandId.inBiot}:${SensorTypeId.Humidity}`,
        [`[${DeviceBrandName.inBiot}] ${SensorTypeName.CO2}`]: `${DeviceBrandId.inBiot}:${SensorTypeId.CO2}`,
        [`[${DeviceBrandName.inBiot}] ${SensorTypeName.Formaldehyde}`]: `${DeviceBrandId.inBiot}:${SensorTypeId.Formaldehyde}`,
        [`[${DeviceBrandName.inBiot}] ${SensorTypeName.VOC}`]: `${DeviceBrandId.inBiot}:${SensorTypeId.VOC}`,
        [`[${DeviceBrandName.inBiot}] ${SensorTypeName.PM1}`]: `${DeviceBrandId.inBiot}:${SensorTypeId.PM1}`,
        [`[${DeviceBrandName.inBiot}] ${SensorTypeName.PM4}`]: `${DeviceBrandId.inBiot}:${SensorTypeId.PM4}`,
        [`[${DeviceBrandName.inBiot}] ${SensorTypeName.PM10}`]: `${DeviceBrandId.inBiot}:${SensorTypeId.PM10}`,
        [`[${DeviceBrandName.inBiot}] ${SensorTypeName.PM25}`]: `${DeviceBrandId.inBiot}:${SensorTypeId.PM25}`,
        [`[${DeviceBrandName.inBiot}] ${SensorTypeName.CO}`]: `${DeviceBrandId.inBiot}:${SensorTypeId.CO}`,
        [`[${DeviceBrandName.inBiot}] ${SensorTypeName.O3}`]: `${DeviceBrandId.inBiot}:${SensorTypeId.O3}`,
        [`[${DeviceBrandName.inBiot}] ${SensorTypeName.NO2}`]: `${DeviceBrandId.inBiot}:${SensorTypeId.NO2}`,

        [`[${DeviceBrandName.PolitoAndSAT}] ${SensorTypeName.Type}`]: `${DeviceBrandId.PolitoAndSAT}:${SensorTypeId.Type}`,
        [`[${DeviceBrandName.PolitoAndSAT}] ${SensorTypeName.DrowsinessState}`]: `${DeviceBrandId.PolitoAndSAT}:${SensorTypeId.DrowsinessState}`,
        [`[${DeviceBrandName.PolitoAndSAT}] ${SensorTypeName.HeartRate}`]: `${DeviceBrandId.PolitoAndSAT}:${SensorTypeId.HeartRate}`,
        [`[${DeviceBrandName.PolitoAndSAT}] ${SensorTypeName.HRV}`]: `${DeviceBrandId.PolitoAndSAT}:${SensorTypeId.HRV}`,
        [`[${DeviceBrandName.PolitoAndSAT}] ${SensorTypeName.Latitude}`]: `${DeviceBrandId.PolitoAndSAT}:${SensorTypeId.Latitude}`,
        [`[${DeviceBrandName.PolitoAndSAT}] ${SensorTypeName.Longitude}`]: `${DeviceBrandId.PolitoAndSAT}:${SensorTypeId.Longitude}`,
        [`[${DeviceBrandName.PolitoAndSAT}] ${SensorTypeName.Altitude}`]: `${DeviceBrandId.PolitoAndSAT}:${SensorTypeId.Altitude}`,
};